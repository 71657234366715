.datainfrastructure-container {
  width: 100%;
}

.data-home {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 95vh;
  margin: 3rem 4rem 0 4rem;
}

.data-home-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.data_landing {
  margin: 4rem 0 0 0;
}

.data-home-content h1 {
  color: #002c5f;
  font-size: 50px;
  font-weight: 700;
  margin: 0 0 2rem 0;
}

.data-home-content p {
  font-size: 20px;
  color: #002c5f;
  margin: 1rem 0 3rem 0;
  font-weight: 300;
}

.data-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-image img {
  max-width: 85%;
  height: auto;
  margin-left: -2rem;
}

.etl_Automation {
  width: 120px;
}

.data_warehouse {
  width: 85px;
}

.api_integration {
  width: 100px;
}

.cloud_implementation {
  width: 85px;
}

.data_migration {
  width: 100px;
}

@media screen and (max-width: 600px) {
  .data-home {
    margin: 3rem 0 0 0;
    text-align: center;
    flex-direction: column;
    padding: 20px;
    height: auto;
  }

  .data-home-content h1 {
    font-size: 24px;
    margin: 0 0 3rem 0;
  }

  .data-image img {
    display: none;
  }

  .data-home-content p {
    font-size: 13px;
    margin: 2rem 0 0 0;
  }

  .data-home-btn {
    font-size: 12px;
    margin: 1rem 0 0 0;
  }

  .etl_Automation {
    width: 85px;
  }

  .data_warehouse {
    width: 63px;
  }

  .api_integration {
    width: 80px;
  }

  .cloud_implementation {
    width: 65px;
  }

  .data_migration {
    width: 75px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .data-home {
    margin: 1rem 0 0 0;
    height: auto;
    padding: 30px;
  }

  .data-home-content h1 {
    font-size: 30px;
    margin: 0 0 1.5rem 0;
  }

  .data-home-content p {
    font-size: 13px;
    margin: 0 0 2.5rem 0;
  }

  .data-image img {
    max-width: 100%;
  }

  .etl_Automation {
    width: 90px;
  }

  .data_warehouse {
    width: 65px;
  }

  .api_integration {
    width: 75px;
  }

  .cloud_implementation {
    width: 65px;
  }

  .data_migration {
    width: 80px;
  }


}
