.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(1px);
  z-index: 1000;
}

.modal-content {
  background: white;
  border-radius: 8px;
  position: relative;
  overflow-y: auto;
  max-height: 95vh;
  width: 90vw;
}

.modal-close {
  position: absolute;
  top: 5px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
}


@media only screen and (max-width: 600px) {
  .modal-close {
    color: #ffffff;
  }

  .modal-overlay {
    align-items: center;
  }
}
