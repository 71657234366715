.etl_table {
  margin: 1% 15%;
  font-size: 18px;
  font-weight: 300;
  line-height: 40px;
  border-collapse: collapse;
  border: 1px solid #002c5f;
}

.etl_table th,
.etl_table td {
  border: 1px solid #002c5f;
  padding: 10px;
  vertical-align: top;
}

.etl_table th {
  background-color: #b1ceef;
}

@media only screen and (max-width: 600px) {
  .etl_table {
    margin: 1% 10%;
    font-size: 12px;
    line-height: 25px;
  }

  .etl_table th,
  .etl_table td {
    padding: 5px;
  }
}
