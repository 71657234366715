.contactus-container {
  color: #002c5f;
  width: 100%;
  margin: 5rem 0 0 0;
  padding: 0 5rem 2rem 5rem;
}

.contactus-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactus-image {
  flex: 1;
}

.contactus-image img {
  width: 500px;
  height: auto;
}

.contactus-image-text-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 2rem 0 0 0;
}

.contactus-form {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactus-text {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.form-bg {
  background-color: #ffffff;
  border-radius: 45px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.55);
}

.contact-btn {
  border-radius: 192px;
  background: #0156b8;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  padding: 0.8rem 3rem;
  left: 50%;
  transform: translate(-50%);
  position: relative;
}

.returnhome-btn {
  border-radius: 192px;
  background: #0156b8;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  padding: 0.8rem 0;
  margin: 15rem 0 0 0;
  padding: 0.7rem 3rem;
}

.contact-btn:hover {
  box-shadow: 0px 2px 10px #b8b8b8;
}

.md\:h-56 {
  height: 8rem;
}

.py-2\.5 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

.PhoneInput input {
  border-radius: 10px;
  border: 1px solid #cdd2db;
  padding: 8px 0px;
  background-color: #f9f9f9;
}

.message {
  width: 100%;
  position: relative;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
}

.message .success {
  font-size: 25px;
  color: #002c5f;
  position: absolute;
  animation: buttons 0.3s linear;
  margin: 22% 5% 0 5%;
  text-align: center;
}

.contactus-email-text {
  display: flex;
  margin: 0 0 0 2rem;
  justify-content: center;
}

.contactus-text {
  margin: 2rem 0 0 0;
  font-size: 18px;
  padding: 0 3rem;
}

.contactus-text p {
  margin: 0rem 0 1rem 0;
  font-size: 17px;
}

.emailid-text {
  font-weight: 600;
}

.contactus-email-text img {
  width: 30px;
  height: 20px;
  margin: 0.2rem 0.5rem 0 0;
}

@keyframes buttons {
  0% {
    transform: scale(0.1);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 600px) {
  .contactus-container {
    width: 100%;
    padding: 0;
  }

  .contactus-main {
    display: flex;
    flex-direction: column;
    align-items: normal;
  }

  .contactus-image img {
    display: block;
    margin: 1rem auto;
    width: 75%;
  }

  .contactus-form {
    margin: -4rem 0 0 0;
  }

  .contactus-text p {
    font-size: 14px;
    padding: 0 0.5rem;
  }

  .contactus-email-text img {
    width: 25px;
    height: 15px;
    margin: 0.1rem 0 0;
  }

  .contact-btn {
    padding: 0.5rem 0;
    font-size: 15px;
    padding: 0.8rem 3rem;
  }

  .message .success {
    font-size: 18px;
  }

  .md\:h-56 {
    height: 8rem;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .contactus-container {
    padding: 0;
  }

  .contactus-image img {
    display: block;
    margin: 0 auto 0 auto;
    width: 80%;
  }

  .contactus-form {
    margin: -4% 0 0 0;
  }

  .contactus-text p {
    font-size: 13px;
    padding: 0 0.5rem;
  }

  .px-6 {
    margin-left: 0;
  }

  .contactus-email-text img {
    width: 25px;
    height: 15px;
    margin: 0.1rem 0 0;
  }

  .contact-btn {
    padding: 0.5rem 0;
    font-size: 13px;
    padding: 0.8rem 3rem;
  }

  .message .success {
    font-size: 18px;
  }

  .md\:h-56 {
    height: 8rem;
  }
}
