.benefits-bi-subheader {
  font-size: 30px;
}

@media only screen and (max-width: 600px) {
    .benefits-bi-subheader {
        font-size: 15px;
      }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .benefits-bi-subheader {
        font-size: 25px;
      }
}