.data-strategy-landing {
  margin: 5rem 0 1rem 0;
  text-align: center;
}

.data-strategy-landing-text {
  padding: 7% 0 0 0;
}

.data-strategy-landing-text h1 {
  font-size: 50px;
  font-weight: 700;
  padding: 0% 0 2rem 0;
}

.data-strategy-landing-text p {
  font-size: 20px;
  font-weight: 300;
  margin: 0 0 3rem 0;
  padding: 0 15%;
}

.data-strategy-landing-box {
  display: flex;
  justify-content: space-around;
  margin: 5% 5% 0 3%;
}

.data-strategy-landing-box h2 {
  font-size: 40px;
  font-weight: 600;
}

.data-strategy-landing-box h3 {
  font-size: 32px;
  font-weight: 600;
}

.data-strategy-landing-box p {
  font-size: 14px;
  font-weight: 400;
  color: #666d76;
}

.vertical {
  border-left: 1px solid #002c5f;
  height: 110px;
  margin: 0 10px;
}

.matrix-box {
  flex: 1;
}

.data-services-offering-desktop-screen {
  margin: 3rem 0 10rem 0;
  padding: 0 10%;
  height: 400px;
}

.data-services-offering-desktop-screen h2 {
  font-size: 40px;
  font-weight: 600;
  margin: 1rem 0;
  text-align: center;
}

.data-services-offering-mobile-screen {
  display: none;
}

.offering-box1 {
  width: 65%;
  height: 400px;
  position: absolute;
  transform: translate(10.5%);
  box-shadow: 5px 5px 10px rgba(43, 69, 78, 0.4);
  padding: 4% 4% 0 27%;
  font-size: 17px;
  border-radius: 10px;
  font-weight: 300;
  margin: 2rem 0 0 1rem;
}

.offering-box2 {
  width: 33%;
  height: 400px;
  margin: 0 4rem;
  background: #b1ceef;
  z-index: 100;
  color: #002c5f;
  cursor: pointer;
  position: relative;
  padding: 2.3% 0 0 0;
  border-radius: 10px;
}

.offering-box2 p.active {
  background-color: #0156b8;
  color: #ffffff;
  text-decoration: underline;
}

.offering-box2 p {
  padding: 0.8rem 1.5rem 0.8rem 1.5rem;
  font-size: 17px;
}

.offering-box2 p:hover {
  text-decoration: underline;
}

.services-list {
  margin: 2rem 0 0 2rem;
  list-style-type: disc;
}

.services-list li {
  margin: 10px 0;
}

.services-list li a {
  font-weight: 500;
  text-decoration: underline;
}

.success-project-container-main h2 {
  font-size: 40px;
  font-weight: 600;
  margin: 7rem 0 1rem 0;
  text-align: center;
}

.success-project-box-section {
  display: flex;
  padding: 0 5rem;
}

.success-project-box {
  flex: 1;
  margin: 2rem;
  padding: 1.5rem 2rem;
  box-shadow: 0px 4px 10px rgba(100, 98, 98, 0.55);
  border-radius: 20px;
  border: #b3adad;
  text-align: center;
}

.success-project-box img {
  display: block;
  margin: 0 auto;
  height: 65px;
}

.success-project-box h3 {
  font-size: 18px;
  margin: 1rem 0;
  font-weight: 400;
  height: 25px;
}

.success-project-box p {
  font-size: 14px;
  font-weight: 400;
  color: #666d76;
}

.case-study-data-strategy {
  margin: 0 0 3rem 0;
}

.case-study-data-strategy h2 {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin: 1rem 0 1rem 0;
}

.tech-tools-container-main h2 {
  font-size: 40px;
  font-weight: 600;
  margin: 2rem 0;
}

.tech-tools-main-box {
  background: #b1ceef;
  margin: 2% 5%;
  padding: 2%;
  border-radius: 53px;
}

.tech-tools-main-box h3 {
  color: #002c5f;
  font-size: 25px;
  font-weight: 400;
}

.white-main-box {
  padding: 0.5rem 0;
  margin: 1% 0 2% 0;
}

.horizontal-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.individual-box {
  width: 195px;
  height: 97px;
  background-color: #ffffff;
  margin: 0.2%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2%;
  border-radius: 6px;
}

.consulting_cta_button {
  background-color: #b1ceef;
  color: #ffffff;
  padding: 0.5rem 0 3rem 0;
  margin: 1.5rem 0 2rem 0;
  text-align: center;
}
.consulting_cta_button p {
  font-size: 18px;
  padding: 0 15%;
  font-weight: 300;
  margin: 0 0 2.5rem 0;
  color: #002c5f;
}

.aws {
  width: 75px;
}

.microsoft_azure {
  width: 170px;
}

.google_cloud {
  width: 250px;
}

.tableau {
  width: 180px;
}

.google_developers_chart {
  width: 160px;
}

.powerBI {
  width: 160px;
}

.QlikSense {
  width: 110px;
}

.Fusion_charts {
  width: 320px;
}

.Looker {
  width: 80px;
}

.Grafana {
  width: 150px;
}

.chartblocks {
  width: 110px;
}

.Microsoft_excel {
  width: 70px;
}

.Chartist {
  width: 150px;
}

.Datawrapper {
  width: 170px;
}

.oracle_business_intelligence {
  width: 170px;
}

.MicroStrategy {
  width: 300px;
}

.IBM_Cognos {
  width: 150px;
}

.D3 {
  width: 60px;
}

.QlikView {
  width: 130px;
}

.Sisense {
  width: 270px;
}

.ApacheAirflow {
  width: 170px;
}

.AWSGlue {
  width: 300px;
}

.OracleDataIntegrator {
  width: 150px;
}

.SQLServer {
  width: 60px;
}

.Talend {
  width: 130px;
}

.Pentaho {
  width: 270px;
}

.ApacheAirflow {
  width: 130px;
}

.AzureDataFactory {
  width: 180px;
}

.AWSGlue {
  width: 200px;
}

.OracleDataIntegrator {
  width: 200px;
}

.SQLServer {
  width: 170px;
}

.Talend {
  width: 170px;
}

.Pentaho {
  width: 150px;
}

.AWSDataPipeline {
  width: 140px;
}

.IBMDataStage {
  width: 170px;
}

.Portable {
  width: 150px;
}

.GoogleCloudDataflow {
  width: 150px;
}

.Stitch {
  width: 140px;
}

.Hevo {
  width: 200px;
}

.IBM_Cognos {
  width: 130px;
}

.SAPDataServices {
  width: 210px;
}

.Qlik {
  width: 120px;
}

.Airbyte {
  width: 130px;
}

.Informatica {
  width: 170px;
}

.amazon_redshift {
  width: 150px;
}

.google_big_query {
  width: 125px;
}

.postgre_SQL {
  width: 80px;
}

.SQL_Server {
  width: 100px;
}

.Snowflake {
  width: 160px;
}

.MySql {
  width: 100px;
}

.amazon_RDS {
  width: 110px;
}

.amazon_dynamo_DB {
  width: 140px;
}

.amazon_s3 {
  width: 110px;
}

.Azure_Cosmos_DB {
  width: 120px;
}

.mongo_db {
  width: 160px;
}

.amazon_aurora {
  width: 110px;
}

.azure_data_lake {
  width: 120px;
}

.amazon_document_DB {
  width: 110px;
}

.teradata {
  width: 160px;
}

.azure_Blob_Storage {
  width: 100px;
}

.azure_synpase_analytics {
  width: 60px;
}

.Alation {
  width: 180px;
}

.ApacheSpark {
  width: 130px;
}

.Cassandra {
  width: 100px;
}

.ApacheHbase {
  width: 160px;
}

.Hive {
  width: 90px;
}

.Kafka {
  width: 130px;
}

.Cloudera {
  width: 170px;
}

.DeltaLake {
  width: 180px;
}

.Hadoop {
  width: 180px;
}

.spark {
  width: 180px;
}

.scikitlearn {
  width: 120px;
}

.spacy {
  width: 130px;
}

.tensorflow {
  width: 150px;
}

.amazonlex {
  width: 120px;
}

.googlecloudai {
  width: 120px;
}

.azuremachinglearning {
  width: 180px;
}

.microsoft_cognitiveservices {
  width: 110px;
}

.amazonsaagemaker {
  /* width: 120px; */
}

.keras {
  width: 150px;
}

.amazontranscribe {
  width: 160px;
}

.amazonpolly {
  width: 90px;
}

.mahout {
  width: 170px;
}

.mxnet {
  width: 140px;
}

.vertexai {
  width: 170px;
}

.what-our-clients-say {
  display: flex;
  margin: 0 5%;
}

.what-our-clients-say-container-main h2 {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin: 2.5rem 0 1rem 0;
}

.consulting_cta_button h2 {
  font-size: 32px;
  font-weight: 400;
  margin: 1rem 0;
  color: #002c5f;
}

.case-studies-btn {
  text-align: center;
  margin: 2rem 0 1rem 0;
}

@media only screen and (max-width: 600px) {
  .data-strategy-landing h1 {
    font-size: 24px;
    padding: 0 1rem 1.5rem 1rem;
  }

  .data-strategy-landing p {
    font-size: 13px;
    padding: 0 8%;
    margin: 0 0 2rem 0;
  }

  .data-strategy-landing-box {
    margin: 3rem 0 0 0;
    flex-direction: column;
  }

  .vertical {
    display: none;
  }

  .data-strategy-landing-box h3 {
    font-size: 22px;
  }

  .data-strategy-landing-box p {
    font-size: 12px;
    margin: 0 0 2rem 0;
  }

  .data-services-offering-desktop-screen {
    display: none;
  }

  .data-services-offering-mobile-screen {
    display: block;
    margin: 0rem 2rem 0rem 2rem;
  }

  .data-services-offering-mobile-screen h2 {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
  }

  .data-services-offering-boxes h3 {
    font-size: 15px;
    background-color: #0156b8;
    border-radius: 4px;
    color: #ffffff;
    padding: 0.5rem;
    margin: 1rem 0 0 0;
  }
  
  .data-services-offering-boxes p {
    font-size: 12px;
    margin: 1rem 0 0 0;
    padding: 0 0 0 0.5rem;
  }

  .success-project-container-main h2 {
    font-size: 20px;
    padding: 0 4rem;
    margin: 2rem 0 0 0;
  }

  .success-project-box-section {
    flex-direction: column;
    padding: 0;
  }

  .success-project-box {
    margin: 1rem 2rem;
    padding: 1rem 2rem;
  }

  .success-project-box h3 {
    font-size: 15px;
  }

  .success-project-box p {
    font-size: 12px;
  }

  .success-project-box img {
    height: 50px;
  }

  .tech-tools-container-main {
    display: none;
  }

  .what-our-clients-say {
    flex-direction: column;
    margin: 0 5% 3% 5%;
  }

  .what-our-clients-say-container-main h2 {
    font-size: 20px;
  }

  .height-mobile-box {
    height: 155px;
  }

  .data-strategy-expert h2 {
    font-size: 18px;
  }

  .case-study-data-strategy h2 {
    font-size: 20px;
  }

  .case-studies-btn {
    margin: 1rem 0 0 0;
  }

  .consulting_cta_button {
    display: none;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .data-strategy-landing-text h1 {
    font-size: 30px;
  }

  .data-strategy-landing-text p {
    font-size: 13px;
    padding: 0 12%;
    margin: 1rem 0 3rem 0;
  }

  .data-strategy-landing-box {
    margin: 6% 3% 6% 2%;
  }

  .data-strategy-landing-box h3 {
    font-size: 20px;
  }

  .data-strategy-landing-box p {
    font-size: 11px;
  }

  .vertical {
    height: 95px;
  }

  .data-services-offering-desktop-screen {
    padding: 0;
  }

  .data-services-offering-desktop-screen h2 {
    font-size: 28px;
  }

  .offering-box2 {
    width: 38%;
    margin: 0 2.2rem;
  }

  .offering-box1 {
    width: 84%;
    padding: 4% 4% 0 35%;
  }

  .success-project-container-main h2 {
    font-size: 28px;
    margin: -1rem 0 0.5rem 0;
  }

  .success-project-box-section {
    padding: 0 1rem;
  }

  .success-project-box h3 {
    font-size: 13px;
    margin: 1rem 0 0 0;
  }

  .success-project-box p {
    font-size: 11px;
  }

  .success-project-box {
    padding: 1rem;
    margin: 1.5rem;
  }

  .success-project-box img {
    height: 53px;
  }

  .case-study-data-strategy h2 {
    font-size: 28px;
  }

  .what-our-clients-say-container-main h2 {
    font-size: 28px;
  }

  .what-our-clients-say {
    flex-direction: column;
    margin: 0 0% 4% 0%;
  }

  .tech-tools-container-main {
    display: none;
  }

  .consulting_cta_button {
    display: none;
  }
}
