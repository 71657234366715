.how_wo_work_h3 {
    font-size: 30px;
    font-weight: 400;
    padding: 1% 7% 0 7%;
}

.data_management_process {
    background-color: #ffffff;
    margin: 0 0 -3rem 0;
}

.data_management_form_text {
    font-size: 17px;
    font-weight: 400;
    margin: 1% 0 2% 0;
}

.business_success_section {
    display: flex;
    justify-content: space-between;
    padding: 0 6.5% 0 3%;
}

.business_success_section1 {
    flex: 0 0 65%;
}

.business_success_section2 {
    flex: 0 0 35%;
    justify-content: center;
    align-items: center;
}
.DataManagementConsultingImage {
    margin: 2.4rem 0 0 0;
}

.data_architecture {
    width: 90px;
}

@media only screen and (max-width: 600px) {
    .business_success_section {
        flex-direction: column;
        padding: 0;
    }

    .business_success_section2 {
       margin: 0 7%;
    }

    .DataManagementConsultingImage {
        margin: 1.3rem 0 0 0;
    }

    .how_wo_work_h3 {
        font-size: 17px;
    }

    .data_architecture {
        width: 65px;
    }
}