.growthmarketing-container {
  width: 100%;
}

.growth-home {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 95vh;
  margin: 3rem 4rem 0 4rem;
}

.growth-home-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.growth_landing {
  margin: 4rem 0 0 0;
}

.growth-home-content h1 {
  color: #002c5f;
  font-size: 50px;
  font-weight: 700;
  margin: 0 0 2rem 0;
}

.growth-home-content p {
  font-size: 20px;
  color: #002c5f;
  font-weight: 300;
  margin: 0 0 3rem 0;
}

.growth-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.growth-image img {
  max-width: 80%;
  height: auto;
}

.wrapper {
  max-width: 600px;
  width: 100%;
  position: relative;
  margin: 6rem 0 0 3rem;
}
.wrapper i {
  top: 50%;
  height: 50px;
  width: 50px;
  cursor: pointer;
  font-size: 1.25rem;
  position: absolute;
  text-align: center;
  line-height: normal;
  transform: translateY(-50%);
  transition: transform 0.1s linear;
}

.wrapper #left {
  left: -3rem;
  background-image: url("../Assets/icons/arrow_left.svg");
  background-repeat: no-repeat;
  width: 25px;
}

.wrapper #right {
  right: -2.8rem;
  background-image: url("../Assets/icons/arrow_right.svg");
  background-repeat: no-repeat;
  width: 25px;
}

.wrapper i:active {
  transform: translateY(-50%) scale(0.85);
}
.wrapper i:first-child {
  left: -22px;
  z-index: 10;
}
.wrapper i:last-child {
  right: -22px;
}
.wrapper .carousel {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: calc((100% / 3));
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 16px;
  border-radius: 8px;
  scroll-behavior: smooth;
  scrollbar-width: none;
}
.carousel::-webkit-scrollbar {
  display: none;
}
.carousel.no-transition {
  scroll-behavior: auto;
}
.carousel.dragging {
  scroll-snap-type: none;
  scroll-behavior: auto;
}
.carousel.dragging .card {
  cursor: grab;
  user-select: none;
}
.carousel :where(.card, .img) {
  display: flex;
  align-items: center;
}
.carousel .card {
  scroll-snap-align: start;
  width: 162px;
  height: 160px;
  list-style: none;
  cursor: pointer;
  padding-bottom: 15px;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 48px;
  background: #b1ceef;
  border-style: none;
  text-align: center;
}

.carousel .card h1 {
  color: #002c5f;
  font-size: 32px;
  font-weight: 500;
  margin: 20px 0 5px;
}

.carousel .card h2 {
  color: #002c5f;
  font-size: 14px;
  font-weight: 500;
}

.carousel .card p {
  border-radius: 4px;
  background: #0156b8;
  font-size: 10px;
  font-weight: 300;
  color: #fff;
  width: 114px;
  height: 18px;
  text-align: center;
  padding: 0.7% 0;
}

.arrow_up_growth {
  display: flex;
}

.arrow_up {
  margin: 0.7rem 0 0 0.5rem;
  width: 27px;
}

.icon-box-section {
  width: 90%;
  margin: 5rem 3rem;
}

.icon-box-section-h1 {
  font-size: 40px;
  font-weight: 600;
  margin: 1rem 0 0 0;
  background-color: #0156b8;
  color: #ffffff;
  padding: 1.5rem 4.5rem;
}

.icon-box-section h2 {
  color: #002c5f;
  font-size: 27px;
  font-weight: 400;
  margin: 0 0 1.5rem 0;
}

.icon-box-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 36px;
  flex: 0 0 80%;
  background: #b1ceef;
  padding: 20px;
  font-size: 17px;
  font-weight: 300;
  width: 1080px;
  height: 230px;
  margin: 0 0 1.5rem 0;
}

.mobile_content {
  display: none;
}

.growth_box_image {
  flex: 0 0 20%;
  margin: -1.5rem 0 0 0;
}

.paid_marketing {
  width: 90px;
}

.media_marketing {
  width: 85px;
}

.ga4 {
  width: 65px;
}

.rate_optimization {
  width: 70px;
}

.seo_sem {
  width: 80px;
}

.services_quote-container {
  background: #b1ceef;
  height: 270px;
  margin: -2rem 0 2rem 0;
}

.services_quote_text {
  padding: 5% 9% 6% 8%;
  place-items: center;
  text-align: center;
}

.services_quote {
  position: relative;
  right: -5%;
  margin-bottom: -10%;
  transform: translateY(-20%);
}

.services_quote img {
  flex: 1;
  max-width: 300px;
}

.services_quote_text h2 {
  color: #002c5f;
  font-size: 32px;
  font-weight: 400;
  margin: 0 0 3rem 0;
}

.growth-home-btn {
  background: #ee712b;
  color: #ffffff;
  padding: 8px 25px;
  border: none;
  border-radius: 192px;
  margin-right: 20px;
  font-size: 16px;
  font-weight: 400;
  margin: 2rem 0 0 0;
}

.growth-home-btn:hover {
  box-shadow: 5px 5px 10px rgba(43, 69, 78, 0.4);
  font-weight: 500;
}

.growth-getin-btn {
  background: #ee712b;
  color: #ffff;
  padding: 8px 25px;
  border: none;
  border-radius: 192px;
  font-size: 22px;
  font-weight: 200;
}

.growth-getin-btn:hover {
  box-shadow: 5px 5px 10px rgba(43, 69, 78, 0.3);
  font-weight: 400;
}

.fixed-button {
  display: none;
}

.services_boxes_section_padding {
  padding: 1rem 3.2rem !important;
}

@media screen and (max-width: 600px) {
  .growth-home {
    margin: 3.5rem 0 0 0;
    text-align: center;
    flex-direction: column;
    padding: 20px;
    height: auto;
  }

  .growth-home-content h1 {
    font-size: 24px;
    margin: 0 0 3rem 0;
  }

  .growth-image img {
    display: none;
  }

  .growth-home-content p {
    font-size: 13px;
    margin: 1rem 0 0 0;
  }

  .growth-home-btn {
    display: none;
  }

  .wrapper {
    max-width: 300px;
    width: 100%;
    position: relative;
    margin: 3rem 0 2rem 23%;
  }

  .wrapper .carousel {
    grid-auto-columns: calc((100% / 1) - 10px);
  }

  .carousel .card {
    width: 190px;
    height: 180px;
    padding-bottom: 15px;
    border-radius: 15px;
  }

  .carousel .card h2 {
    margin: 1rem 0 0 0;
  }

  .wrapper #left {
    background-image: url("../Assets/icons/arrow_left_mobile.svg");
    margin: 1rem 0 0 0.2rem;
  }

  .wrapper #right {
    background-image: url("../Assets/icons/arrow_right_mobile.svg");
    right: 3.4rem;
    margin: 1rem 0 0 0;
  }

  .wrapper .carousel {
    grid-auto-columns: 100%;
  }

  .icon-box-section {
    width: 95%;
    margin: 2rem 0 0 0;
  }

  .mobile_content {
    display: block;
    color: #002c5f;
  }

  .desktop_box_content {
    display: none;
  }

  .icon-box-section-h1 {
    font-size: 20px;
    padding: 1rem;
    text-align: center;
    margin: 0 0 1rem 0;
  }

  .icon-box-section h2 {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    margin: 0.5rem 0 1rem 0;
  }

  .icon-box-row {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .box {
    padding: 10px;
    color: #fff;
    font-size: 10px;
    font-weight: 300;
    width: 279px;
    height: 141px;
    margin: 0 0 1rem 0;
    flex: 0 0 75%;
    text-align: center;
  }

  .growth_box_image {
    flex: 0 0 25%;
    margin: -1.5rem 0 0 0;
  }

  .ga4 {
    width: 55px;
    margin: 0 0rem 0 1rem;
  }

  .rate_optimization {
    margin: 0;
  }

  .seo_sem {
    margin: 1rem 0 0 0rem;
  }

  .mobile_reverse {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 1rem;
    margin-right: -1rem;
  }

  .services_quote-container {
    display: none;
  }

  .fixed-button {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 8px 25px;
    background-color: #ee712b;
    color: white;
    border: none;
    border-radius: 192px;
    z-index: 10;
    font-size: 13px;
    box-shadow: 5px 5px 10px rgba(43, 69, 78, 0.3);
  }

  .services_boxes_section_padding {
    padding: 0.5rem 0.5rem !important;
    margin: -1rem 0 0 0 !important;
  }

  .paid_marketing {
    width: 60px;
  }
  
  .media_marketing {
    width: 60px;
  }
  
  .ga4 {
    width: 45px;
  }
  
  .rate_optimization {
    width: 55px;
  }
  
  .seo_sem {
    width: 60px;
  }

  .services_quote_text h2 {
    font-size: 25px;
    margin: 2rem 0 3rem 0;
  }
  
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .growth-home {
    margin: 1rem 0 0 0;
    height: auto;
    padding: 30px;
  }

  .growth-home-content h1 {
    font-size: 30px;
    margin: 0 0 1.5rem 0;
  }

  .growth-home-content p {
    font-size: 13px;
    margin: 0 0 2.5rem 0;
  }

  .growth-image img {
    max-width: 100%;
  }

  .icon-box-section {
    margin: 3rem;
  }
  .icon-box-section-h1 {
    font-size: 28px;
    margin: 0.5rem 0;
    padding: 1.5rem 30px;
  }

  .icon-box-section h2 {
    font-size: 17px;
    margin: 0 0 1rem 0;
  }

  .mobile_content {
    display: none;
  }

  .desktop_box_content {
    font-size: 13px;
  }

  .carousel .card {
    width: 135px;
    height: 120px;
    border-radius: 18px;
  }
  .carousel .card h1 {
    font-size: 20px;
  }

  .carousel .card h2 {
    font-size: 11px;
  }
  .carousel .card p {
    font-size: 8px;
    margin: 0.5rem 0 0 0;
    height: auto;
  }

  .wrapper {
    max-width: 440px;
    margin: 3.5rem 0 0 1rem;
  }

  .wrapper .carousel {
    gap: 5px;
  }

  .wrapper #left {
    background-image: url("../Assets/icons/arrow_left_mobile.svg");
    margin: 1rem 0 0 0.2rem;
    left: -2rem;
  }

  .wrapper #right {
    background-image: url("../Assets/icons/arrow_right_mobile.svg");
    margin: 1rem 0.5rem 0 0;
  }

  .box {
    height: 210px;
  }

  .services_quote-container {
    height: 200px;
  }
  .services_quote_text h2 {
    font-size: 18px;
  }

  .growth-home-btn {
    font-size: 13px;
  }

  .services_boxes_section_padding {
    padding: 0 0.5rem !important;
  }

  .paid_marketing {
    width: 70px;
  }

  .media_marketing {
    width: 60px;
  }

  .ga4 {
    width: 50px;
  }

  .rate_optimization {
    width: 55px;
  }

  .seo_sem {
    width: 60px;
  }

  .services_button {
    display: block;
  }
}
