.case-study-main-container {
  margin: 5.9rem 0 2rem 0;
  color: #002c5f;
}

.case_studies-image {
  width: 45%;
  display: block;
  margin: 3rem auto;
  padding: 7% 0 0 0;
}

.case-study-content h1 {
  font-size: 38px;
  font-weight: 500;
  margin: 0 0 1rem 0;
  padding: 1% 20%;
}

.case-study-content-p {
  font-size: 18px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.22px;
  padding: 1% 20%;
}

.case-study-content h2 {
  font-size: 28px;
  font-weight: 600;
  padding: 1% 20%;
  text-decoration: underline;
}

.case-study-content ol {
  list-style-type: decimal;
  padding: 1% 23%;
}

.case-study-content ul {
  list-style-type: disc;
}

.case-study-content li {
  font-size: 18px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.22px;
}

.case-study-li-ul {
    padding: 1% 6%;
}

.case-study-ul-list {
  list-style-type: disc;
  padding: 1% 23%;
}

.case-study-content strong {
    font-weight: 600;
}

.results-span {
    padding: 0 20%;
    font-size: 18px;
}

.industry-technology-block {
  display: flex;
  padding: 1% 20%;
  justify-content: space-evenly;
}

.industry-block {
  flex: 1;
  border-left: 2px solid #002c5f;
  padding: 1rem;
}

.technology-block {
  flex: 1;
  border-left: 2px solid #002c5f;
  padding: 1rem;
}

.case-study-content h3 {
  font-size: 22px;
  font-weight: 500;
}

.industry-text {
  color: #666d76;
}

.case_studies-image2 {
  width: 80%;
  display: block;
  /* margin: 3rem auto; */
  padding: 2% 20%;
}

@media only screen and (max-width: 600px) {
  .case_studies-image {
    width: 80%;
    margin: 1rem auto;
  }

  .case-study-content h1 {
    font-size: 17px;
    line-height: 25px;
    margin: 0rem 0 1rem 0;
    padding: 1% 15%;
  }

  .industry-technology-block {
    flex-direction: column;
    padding: 1% 15%;
  }

  .industry-text {
    font-size: 14px;
  }

  .case-study-content h3 {
    font-size: 15px;
  }

  .case-study-content-p {
    font-size: 14px;
    line-height: 25px;
    padding: 1% 15%;
  }

  .case-study-content h2 {
    font-size: 16px;
    font-weight: 600;
    padding: 3% 15%;
  }

  .case-study-content ol {
    list-style-position: inside;
    list-style-type: decimal;
    padding: 1% 15% 1% 18%;
  }

  .case-study-li-ul {
    padding: 1% 8%;
  }

  .case-study-content li {
    font-size: 14px;
    line-height: 25px;
  }

  .results-span {
    font-size: 14px;
    padding: 1% 15%;
  }

  .case_studies-image2 {
    padding: 0;
    margin: 1rem 0 1rem 15%;
    width: 110%;
  }

}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .case-study-main-container {
    margin: 5rem 0 2rem 0;
  }

  .case-study-content h1 {
    font-size: 30px;
    padding: 1% 8%;
  }

  .case_studies-image {
    width: 70%;
    margin: 2rem auto;
    padding: 12% 0 0 0;
  }

  .industry-technology-block {
    padding: 1% 8%;

  }

  .case-study-content h3 {
    font-size: 15px;
  }

  .industry-text {
    font-size: 13px;
  }

  .case-study-content h2 {
    font-size: 17px;
    padding: 1% 8%;

  }

  .case-study-content-p {
    font-size: 13px;
    padding: 1% 8%;
    line-height: 30px;
  }

  .case-study-content ol {
    padding: 1% 8%;

  }

  .results-span {
    padding: 1% 8%;
    font-size: 15px;
  }

  .case-study-ul-list {
    padding: 1% 13%;

  }

  .case-study-content li {
    font-size: 13px;

  }

  .case_studies-image2 {
    width: 100%;
    padding: 1% 8% 2% 8%;
  }

}