.datastrategy-container {
  display: flex;
  height: 100%;
  width: 100%;
}

.form-section1-text {
  flex: 1;
  text-align: center;
  color: #ffffff;
  background-color: #0156b8;
  padding: 8% 0;
}

.form-section1-text h1 {
  font-size: 32px;
  font-weight: 300;
  margin: 3rem 0;
}

.form-section1-text p {
  font-size: 17px;
  font-weight: 200;
  margin: 3rem 0;
  padding: 0 3rem;
}

.datastrategy-form {
  flex: 1;
  padding: 3.5rem 2.5rem 0 2.5rem;
  height: 100%;
  background-color: #ffffff;
}

.mb-6 {
  margin-bottom: 1rem;
}

.gap-6 {
  gap: 1rem;
}

.message-datastrategy {
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  margin: 40% 0 0 0;
}

.success-datastrategy {
  font-size: 18px;
  color: #002c5f;
  position: absolute;
  animation: buttons 0.3s linear;
  text-align: center;
  padding: 0 2rem;
}

.insights_form_section1_text {
  flex: 1;
  text-align: center;
  color: #ffffff;
  background-color: #ee712b;
  padding: 8% 0;
}

.insights_form_section1_text h1 {
  font-size: 42px;
  font-weight: 300;
  margin: 5.5rem 0 2rem 0;
}

.insights_form_section1_text p {
  font-size: 17px;
  font-weight: 200;
  margin: 3rem 0;
  padding: 0 3rem;
}

@media only screen and (max-width: 600px) {
  .datastrategy-container {
    display: flex;
    flex-direction: column;
  }

  .form-section1-text h1 {
    font-size: 17px;
    margin: 1rem 0 -1rem 0;
  }

  .form-section1-text p {
    font-size: 11px;
    margin: 2rem 0 0 0;
    padding: 0 1rem;
  }

  .datastrategy-form {
    padding: 1.5rem;
  }

  .insights_form_section1_text h1 {
    font-size: 20px;
    margin: 1rem 0 -1rem 0;
  }
  
  .insights_form_section1_text p {
    font-size: 11px;
    margin: 2rem 0 0 0;
    padding: 0 1rem;
  }

  .message-datastrategy {
    margin: 0;
  }

  .success-datastrategy {
    font-size: 15px;
    align-items: center;
    margin: 0.5rem 0 0 0;
    position: relative;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .form-section1-text h1 {
    font-size: 28px;
    padding: 0 1rem;
  }

  .form-section1-text p {
    font-size: 10px;
    padding: 0 1rem;
  }

  .datastrategy-form {
    padding: 2rem 1rem;
  }

  .text-sm {
    font-size: 12px;
  }

}