.Statistic {
    font-size: 22px;
    font-weight: 500;
    padding: 0 2rem;
}

.image-src-google {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px !important;
    margin: 0 0 0 -4%;
}

@media only screen and (max-width: 600px) {
    .Statistic {
        font-size: 15px;
    }
}


@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .Statistic {
        font-size: 15px;
    }

    .image-src-google {
        font-size: 10px !important;
    }
}