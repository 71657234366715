.businessintelligence-container {
  width: 100%;
}

.business-home {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 95vh;
  margin: 2.1rem 4rem 0 4rem;
}

.business-home-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.business_landing {
  margin: 4rem 0 0 0;
}

.business-home-content h1 {
  color: #002c5f;
  font-size: 50px;
  font-weight: 700;
  margin: 0 0 2rem 0;
}

.business-home-content p {
  font-size: 20px;
  color: #002c5f;
  font-weight: 300;
  margin: 0 0 3rem 0;
}

.business-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.business-image img {
  max-width: 100%;
  height: auto;
}

.business_slider_p {
  border-radius: 4px;
  background: #0c7189;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  width: 130px;
  height: 28px;
  text-align: center;
  padding: 1% 0;
}

.arrow_down {
  margin: 0.7rem 0 0 0.5rem;
  transform: scaleY(-1);
  width: 27px;
}

.channel_attribution {
  width: 100px;
}

.customer_analytics {
  width: 90px;
}

.web_analytics {
  width: 70px;
}

.data_visualization {
  width: 85px;
}

.data_governance {
  width: 80px;
}

.business_services_quote_text {
  padding: 4.5% 0% 6% 0;
  place-items: center;
  text-align: center;
}

.business_services_quote_text h2 {
  color: #002c5f;
  font-size: 32px;
  font-weight: 400;
  margin: 0 0 3rem 0;
}

@media screen and (max-width: 600px) {
  .business-home {
    margin: 3.5rem 0 0 0;
    text-align: center;
    flex-direction: column;
    padding: 20px;
    height: auto;
  }

  .business-home-content h1 {
    font-size: 24px;
    margin: 0 0 3rem 0;
  }

  .business-image img {
    display: none;
  }

  .business-home-content p {
    font-size: 13px;
    margin: 1rem 0 0 0;
  }

  .business-home-btn {
    font-size: 12px;
    margin: 2rem 0 0 0;
  }

  .channel_attribution {
    width: 70px;
  }
  
  .customer_analytics {
    width: 65px;
  }
  
  .web_analytics {
    width: 50px;
  }
  
  .data_visualization {
    width: 60px;
  }
  
  .data_governance {
    width: 60px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .business-home {
    margin: 1rem 0 0 0;
    height: auto;
    padding: 30px;
  }

  .business-home-content h1 {
    font-size: 30px;
    margin: 0 0 1.5rem 0;
  }

  .business-home-content p {
    font-size: 13px;
    margin: 0 0 2.5rem 0;
  }

  .business-image img {
    max-width: 100%;
  }

  .business_services_quote_text h2 {
    font-size: 18px;
  }

  .channel_attribution {
    width: 70px;
  }
  
  .customer_analytics {
    width: 65px;
  }
  
  .web_analytics {
    width: 55px;
  }
  
  .data_visualization {
    width: 65px;
  }
  
  .data_governance {
    width: 55px;
  }
}
