.ai_consulting_services_boxes {
  padding: 0 10% 0 10%;
}

.ai_consulting_datastrategy_Form {
  padding: 1% 7% 2% 7%;
  margin: 2rem 0 2.5rem 0;
  background-color: #b1ceef;
}

.how_we_work_section {
  background-color: #b1ceef;
  padding: 0.5rem 0 2rem 0;
}

.ai_consulting_how_we_work {
  display: flex;
  padding: 1% 6%;
}

.ai_consulting_box {
  flex: 1;
  margin: 0.5rem 1rem;
  padding: 1rem 0;
  box-shadow: 0px 4px 10px rgba(100, 98, 98, 0.55);
  border-radius: 20px;
  border: #b3adad;
  text-align: center;
  background-color: #ffffff;
}

.ai_consulting_box h3 {
  font-size: 18px;
  margin: 0 0 1rem 0;
  font-weight: 500;
  height: 40px;
  padding: 0 7%;
}

.ai_consulting_box p {
  font-size: 14px;
  font-weight: 400;
  color: #666d76;
}

.ai_box_numbers h5 {
  font-size: 50px;
}

.ai_consulting_form_text {
  font-size: 25px;
  font-weight: 400;
  color: #002c5f;
  margin: 1rem 0;
}

.message_ai_consulting {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.success-ai_consulting {
  font-size: 18px;
  color: #002c5f;
  animation: buttons 0.3s linear;
  text-align: center;
  padding: 16.5% 10%;
}

.ipad_ai_consulting_box {
  display: flex;
  flex: 1;
}

.width-message {
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .ai_consulting_services_boxes {
    padding: 0;
  }

  .ai_consulting_how_we_work {
    flex-direction: column;
  }

  .ai_box_numbers h5 {
    font-size: 20px;
  }

  .ai_consulting_box h3 {
    font-size: 15px;
    height: auto;
    margin: 0.5rem 0;
  }

  .ai_consulting_box p {
    font-size: 12px;
  }

  .ai_consulting_form_text {
    display: none;
  }

  .ai_consulting_datastrategy_Form {
    padding: 7%;
    margin: 1rem 0 2rem 0;
  }

  .ipad_ai_consulting_box {
    flex-direction: column;
  }

  .width-message {
    width: 100%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .ai_consulting_box h3 {
    font-size: 13px;
    height: auto;
    margin: 0.5rem 0;
  }

  .ai_consulting_box p {
    font-size: 12px;
  }

  .ai_consulting_how_we_work {
    flex-direction: column;
  }
}
