.ai-bi-text {
  padding: 1% 0;
}

.ai_bi_image {
  width: 60%;
  display: block;
  margin: 3rem auto;
}

.ai-bi-form {
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: -0.22px;
  padding: 1% 15%;
}

@media only screen and (max-width: 600px) {
  .ai-bi-form {
    margin: 0 0 0 -5%;
    font-size: 15px;
  }

  .ai_bi_image {
    width: 80%;
    padding: 0;
    margin: 1rem auto;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .ai_bi_image {
    width: 80%;
    margin: 2rem auto;
  }

  .ai-bi-form {
    padding: 1% 8%;
    font-size: 15px;

  }

}