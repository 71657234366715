.services-container-main {
  color: #002c5f;
  width: 100%;
}

.services-container-landing {
  /* background: linear-gradient(
    245deg,
    rgba(124, 204, 191, 0.7) 17.84%,
    rgba(220, 241, 238, 0.7) 47.09%,
    rgba(255, 255, 255, 0.7) 57.96%
  ); */
  margin: 5% 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.services-container-landing h1 {
  margin: 6% 0 0 0;
  font-size: 50px;
  font-weight: 700;
}

.services-container-landing p {
  font-size: 20px;
  padding: 2% 13% 3% 13%;
  font-weight: 300;
}

.services-container-main h2 {
  font-size: 40px;
  font-weight: 600;
  margin: 2rem 0 0rem 0;
  text-align: center;
}

.consulting-services {
  display: flex;
  height: 250px;
  margin: 3% 4% 1% 4%;
  border: 2px solid #0156b8;
  color: #002c5f;
  background-color: #ffffff;
  border-radius: 70px;
  bottom: 2rem;
  /* width: 90%; */
}

.consulting-heading {
  padding: 1rem;
  flex: 0 0 30%;
}

.consulting-heading h2 {
  font-weight: 500;
  font-size: 32px;
  margin: 2rem 0 1.5rem 0;
}

.consulting-keywords {
  display: flex;
  flex: 0 0 65%;
  text-align: left;
  justify-content: space-around;
}

.keywords {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.consulting-keywords span {
  padding: 1rem;
  font-size: 17px;
}

.services-container-main h3 {
  font-size: 30px;
}

.offer-section {
  margin: 2.5rem 0 0 0;
}

.what-we-offer {
  display: flex;
  justify-content: space-around;
  margin: -2rem 3% 0 3%;
  text-align: center;
}

.offer-box {
  border-radius: 20px;
  box-shadow: 2px 2px 8px #e9e6e6;
}

.growth-box h4 {
  background-color: #0156b8;
  border-radius: 20px 20px 0 0;
  color: #ffffff;
  padding: 1rem;
  font-size: 21px;
}

.offer-box h5 {
  font-size: 20px;
  text-align: left;
  padding: 1rem 1rem 0.3rem 1rem;
}

.offer-box p {
  font-size: 12px;
  text-align: left;
  padding: 0rem 1rem;
  color: #666d76;
}

.what-they-say {
  display: flex;
  padding: 1% 3%;
}

.what-they-say-text {
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  height: 290px;
  line-height: 30px;
  margin: 1rem 0 -6rem 0;
}

.services-name {
  text-align: right;
  font-size: 13px;
}

.what-they-say-box {
  flex: 1;
  background: #b1ceef;
  border-radius: 24px;
  padding: 2rem;
  margin: 1rem;
  font-style: italic;
}

.services-comma {
  transform: scaleX(-1);
  width: 90px;
}

.data-strategy-expert {
  background-color: #b1ceef;
  color: #002c5f;
  padding: 0.5rem 0 3rem 0;
  margin: 1.5rem 0 2rem 0;
  text-align: center;
}

.data-strategy-expert h5 {
  font-size: 32px;
  font-weight: 400;
  margin: 1rem 0;
}

.data-strategy-expert p {
  font-size: 18px;
  padding: 0 15%;
  font-weight: 300;
  margin: 0 0 2.5rem 0;
}

.growth-box {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 1rem;
}

.rate_optimization_services {
  height: 115px;
  display: block;
  margin: 0 auto;
}

.rate_optimization_services img {
  width: 110px;
  transform: translate(-10%);
}

.data_visualization_services {
  height: 115px;
  display: block;
  margin: 0 auto;
}

.data_visualization_services img {
  width: 110px;
  transform: translate(-10%);
}

.api_integration_services {
  height: 115px;
  display: block;
  margin: 0 auto;
}

.api_integration_services img {
  width: 120px;
}

.outcomes-expect {
  display: flex;
  text-align: left;
  padding: 1rem 8rem;
}

.outcomes-box {
  flex: 1;
  border-radius: 15px;
  /* margin: 2rem; */
  /* box-shadow: 0px 4px 10px rgba(100, 98, 98, 0.55); */
  margin: 0 4rem 0 0;
}

.outcomes-box h5 {
  font-size: 21px;
  height: 45px;
  margin: 0 0 0 0;
  background-color: #0156b8;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 15px 15px 0 0;
}

.outcomes-box p {
  font-size: 14px;
  padding: 1rem;
  line-height: 25px;
  color: #666d76;
}

.subservices-div {
  height: 100px;
}

.subservices-learn-more {
  height: 50px;
}

@media only screen and (max-width: 600px) {
  .services-container-landing {
    margin: 5.5rem 0 -2.5rem 0;
    height: auto;
  }

  .services-container-landing h1 {
    font-size: 24px;
  }

  .services-container-landing p {
    font-size: 13px;
    margin: 1rem 0 1rem 0;
  }

  .consulting-services {
    height: 147px;
    margin: 5rem 1rem 1rem 1rem;
    position: relative;
  }

  .consulting-keywords {
    display: none;
  }

  .consulting-heading {
    flex: 100%;
  }

  .consulting-heading h2 {
    margin: 0.5rem 0 2rem 0;
  }

  .services-container-main h2 {
    font-size: 20px;
  }

  .what-we-offer {
    flex-direction: column;
  }

  .we-offer-h2 {
    padding: 0rem 0 3rem 0;
  }

  .offer-section {
    margin: 2rem 0 0 0;
  }

  .rate_optimization_services img {
    display: none;
  }

  .data_visualization_services img {
    display: none;
  }

  .api_integration_services img {
    display: none;
  }

  .growth-box {
    margin-top: -6.5rem;
  }

  .growth-box h4 {
    font-size: 15px;
  }

  .offer-box h5 {
    font-size: 12px;
  }

  .offer-box p {
    font-size: 10px;
  }

  .subservices-div {
    height: auto;
  }

  .outcomes-you-can-expect {
    margin: -1rem 0 0 0;
  }

  .outcomes-expect {
    flex-direction: column;
    padding: 0;
    margin: 1rem 0 0 0;
  }

  .outcomes-box {
    margin: 0 2rem;
  }

  .outcomes-box h5 {
    font-size: 15px;
    height: auto;
    margin: 0;
    font-weight: 500;
  }

  .outcomes-box p {
    font-size: 10px;
    width: 100%;
    margin: 0;
  }

  .what-they-say-box {
    padding: 1rem;
  }

  .what-they-say {
    flex-direction: column;
  }

  .services-comma {
    display: none;
  }

  .what-they-say-text {
    font-size: 10px;
    height: 110px;
    line-height: 25px;
    margin: 0;
  }

  .services-name {
    font-size: 9px;
  }

  .data-strategy-expert p {
    font-size: 12px;
    margin: 0 0 1.5rem 0;
  }

  .data-strategy-expert {
    padding: 1.5rem 0.5rem;
  }

  .services-container-landing1 {
    height: auto;
  }

  .services-container-landing2 {
    height: auto;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .services-container-landing h1 {
    font-size: 30px;
    margin: 13% 0 2% 0;
  }

  .services-container-landing p {
    font-size: 13px;
    margin: 0% 0 2% 0;
  }

  .consulting-heading {
    padding: 1.5rem 0;
  }

  .consulting-heading h2 {
    margin: 0 0 1rem 0;
  }
  .consulting-keywords span {
    font-size: 11px;
    padding: 0.5rem;
  }

  .consulting-services {
    margin: 2rem 1rem 0rem 1rem;
    height: 176px;
    border-radius: 40px;
  }

  .services-container-main h2 {
    font-size: 28px;
  }

  .what-we-offer {
    margin: -1rem 0 0 0;
  }

  .growth-box {
    margin: 1rem;
  }

  .growth-box h4 {
    font-size: 13px;
  }

  .offer-box h5 {
    font-size: 13px;
  }

  .offer-box p {
    font-size: 9px;
  }

  .subservices-div {
    height: 80px;
  }

  .rate_optimization_services {
    height: 95px;
  }

  .data_visualization_services {
    height: 95px;
  }

  .api_integration_services {
    height: 95px;
  }

  .rate_optimization_services img {
    width: 85px;
  }

  .data_visualization_services img {
    width: 95px;
  }

  .api_integration_services img {
    width: 105px;
  }

  .outcomes-expect {
    padding: 0rem;
    margin: 1rem 1rem;
  }

  .outcomes-box {
    margin: 1rem;
  }
  
  .outcomes-box h5 {
    font-size: 17px;
  }

  .outcomes-box p {
    font-size: 13px;
  }

  .what-they-say {
    flex-direction: column;
    margin: 0 0 2rem 0;
  }

  .data-strategy-expert {
    display: none;
  }

  .what-they-say-box {
    margin: 1.5rem 12%;
  }

  .what-they-say-text {
    font-size: 13px;
    height: 220px;
  }

  .services-name {
    font-size: 12px;
  }
  
}