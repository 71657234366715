.bi-healthcare-form {
  font-weight: 600;
  font-size: 18px;
  line-height: 40px;
  letter-spacing: -0.22px;
  text-decoration: underline;
}

.unlocking-power {
  font-size: 28px;
  font-weight: 600;
  padding: 0 2rem;
}

@media only screen and (max-width: 600px) {
  .unlocking-power {
    font-size: 16px;
  }

  .bi-healthcare-form {
    font-size: 14px;
    line-height: 0;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .unlocking-power {
      font-size: 17px;
  }

.bi-healthcare-form {
  font-size: 13px;
 }
}
