.marketing_analytics_landing_boxes {
  display: flex;
  justify-content: space-around;
  padding: 0 4rem;
}

.marketing_analytics_box {
  width: 367px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0% 4%;
  margin: 3rem 1rem 2rem 1rem;
  background-color: #0156b8;
  color: #ffffff;
}

.marketing_analytics_box h3 {
  font-size: 19px;
  font-weight: 500;
}

.mobile_landing_boxes {
  display: flex;
  flex-direction: column;
}

.data-driven-insights-container h2 {
  font-size: 40px;
  font-weight: 600;
  padding: 1% 7% 0 7%;
  margin: 1rem 0 0 0;
}

.data-driven-insights-container p {
  font-size: 17px;
  font-weight: 400;
  padding: 1% 7% 0 7%;
}

.data-driven-insights-container ul {
  list-style-type: disc;
  padding: 1% 10% 0 10%;
}

.data-driven-insights-section {
  display: flex;
  margin: 1rem 0;
  text-align: center;
  padding: 1% 7% 2% 7%;
}

.data-driven-insights-box {
  flex: 1;
  margin: 1rem 1rem;
  padding: 1rem 1.5rem;
  box-shadow: 0px 4px 10px rgba(100, 98, 98, 0.55);
  border-radius: 20px;
  border: #b3adad;
  width: 426px;
  height: auto;
}

.data-driven-insights-box h3 {
  font-size: 18px;
  margin: 0.5rem 0 0.8rem 0;
  font-weight: 500;
  height: 40px;
}

.data-driven-insights-box p {
  font-size: 14px;
  font-weight: 400;
  color: #666d76;
}

.data-driven-insights-box-img {
  height: 60px;
  margin: 0 0 1rem 0;
}

.data-driven-insights-box img {
  display: block;
  margin: 0 auto;
}

.customized_marketing_container {
  margin: 1rem 0;
}

.customized_marketing_container h2 {
  font-size: 40px;
  font-weight: 600;
  padding: 0 7% 0 7%;
  margin: 1.5rem 0 0rem 0;
}

.customized_marketing_container p {
  font-size: 17px;
  font-weight: 400;
  padding: 1% 7% 0% 7%;
}

.data-driven-insights-para {
  font-size: 17px;
  font-weight: 400;
  padding: 1% 7% 0 7%;
}

.customized_marketing_container h3 {
  font-size: 21px;
  font-weight: 500;
  padding: 1% 7% 0.5% 7%;
}

.customized_marketing_container ul {
  list-style-type: disc;
  padding: 0% 10%;
}

.customized_marketing_container li {
  font-size: 17px;
}

.marketing_images_section {
  display: flex;
  padding: 3% 7% 2% 7%;
}

.marketing_image1 {
  width: 48%;
  margin-right: 2%;
}

.marketing_image3 {
  width: 48%;
  margin-left: 2%;
}

.marketing_image2 {
  padding: 2% 7% 0.5% 7%;
}

.marketing_services_container {
  display: flex;
  padding: 0 5%;
  margin: 1rem auto;
  max-width: 1400px;
  /* gap: 1rem; */
}

.marketing_services_box {
  flex: 1;
  margin: 2%;
}

.marketing_services_image img {
  width: 441px;
  /* height: 204px; */
}

.marketing_cta_text p {
  font-size: 25px;
  font-weight: 400;
  color: #002c5f;
  margin: 1rem 0 3rem 0;
  line-height: 44px;
}
.marketing_cta_text {
  padding: 2% 9% 0 8%;
  place-items: center;
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .marketing_analytics_landing_boxes {
    flex-direction: column;
    margin: 1rem 0 0 -0.5rem;
    align-items: center;
  }

  .marketing_analytics_box {
    margin: 1rem 0;
    width: 285px;
    height: 56px;
  }

  .mobile_landing_boxes {
    flex-direction: column-reverse;
  }

  .marketing_analytics_box h3 {
    font-size: 13px;
  }

  .data-driven-insights-container h2 {
    font-size: 20px;
    margin: 1rem 0 0.5rem 0;
  }

  .data-driven-insights-container p {
    font-size: 12px;
  }

  .data-driven-insights-container ul {
    font-size: 12px;
    padding: 0 13%;
  }

  .data-driven-insights-section {
    flex-direction: column;
  }

  .data-driven-insights-box {
    padding: 1rem 1.5rem;
    width: auto;
  }

  .data-driven-insights-box h3 {
    font-size: 15px;
    height: auto;
    margin: 0.5rem 0;
  }

  .data-driven-insights-box p {
    font-size: 12px;
  }

  .data-driven-insights-box img {
    /* width: 28%; */
  }

  .data-driven-insights-para {
    font-size: 12px;
    margin: -1rem 0;
  }

  .customized_marketing_container h2 {
    font-size: 20px;
    margin-bottom: 0;
  }

  .customized_marketing_container p {
    font-size: 12px;
  }

  .customized_marketing_container ul {
    font-size: 12px;
    padding: 0 13%;
  }

  .customized_marketing_container li {
    font-size: 12px;
  }

  .customized_marketing_container h3 {
    font-size: 15px;
  }

  .marketing_services_container {
    flex-direction: column;
    padding: 0 10%;
    margin: 2rem 0;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .marketing_analytics_box {
    width: 240px;
    height: 91px;
  }

  .marketing_analytics_landing_boxes {
    padding: 0 2rem;
  }

  .marketing_analytics_box h3 {
    font-size: 15px;
  }

  .data-driven-insights-container h2 {
    font-size: 28px;
  }

  .data-driven-insights-container p {
    font-size: 13px;
  }

  .data-driven-insights-section {
    padding: 0 2%;
  }

  .data-driven-insights-box h3 {
    font-size: 13px;
    height: 25px;
  }

  .data-driven-insights-box p {
    font-size: 11px;
  }

  .data-driven-insights-box-img {
    /* height: 40px; */
  }

  .data-driven-insights-para {
    font-size: 13px;
  }

  .customized_marketing_container h2 {
    font-size: 28px;
  }

  .customized_marketing_container p {
    font-size: 13px;
  }

  .customized_marketing_container h3 {
    font-size: 17px;
  }

  .customized_marketing_container li {
    font-size: 13px;
  }

  .marketing_services_container {
    padding: 0 1%;
    margin: 3% 1%;
  }

  .marketing_services_image img {
    height: 135px;
  }
}
