.blog-container {
  color: #002c5f;
  padding: 0 2%;
}

.blog-home {
  margin: 5rem 0 0 0;
  padding: 4rem 0 0 0;
}

.blog-home h1 {
  text-align: center;
  font-size: 70px;
  font-weight: 700;
}

.blog-home p {
  text-align: center;
  font-size: 22px;
  font-weight: 400;
  margin: 2rem 0 0 0;
  padding: 0 15%;
}

.blog-home-box {
  display: flex;
  justify-content: center;
}

.blog-home h3 {
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  border-radius: 15px;
  border: 1px solid #002c5f;
  padding: 10px 20px;
  margin: 2rem 1rem 3rem 1rem;
  display: flex;
  align-items: center;
}

.blog-home h3:hover {
  cursor: pointer;
}

.blog-home-box h3.active {
  background-color: #002c5f;
  color: #ffffff;
}

.data-lake-blog {
  display: flex;
  flex-direction: column;
  margin: 0.7rem 0;
  flex: 1;
}

.article-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  max-width: 1500px;
  margin: 1rem auto 3rem auto;
}

.blog1-content {
  padding: 20px 20px 0 0;
  background-color: #b1ceef;
  height: 170px;
}

.data-lake-blog img {
  width: 490px;
  height: 300px;
}

.blog1-content h1 {
  font-size: 18px;
  font-weight: 500;
  margin: 1rem 0;
  padding: 0 15px;
  height: 80px;
}

.blog1-content h4 {
  font-size: 15px;
  font-weight: 400;
  background-color: #ee712b;
  color: #ffffff;
  width: 65%;
  padding: 5px 15px;
  margin: -2.3rem 0 0 0;
}

.blog1-content p {
  font-size: 15px;
  font-weight: 400;
  margin: 0 0 0.5rem 0;
  padding: 0 15px;
}

.blog1-content a {
  font-size: 15px;
  font-weight: 400;
  margin: 0 0 0.5rem 0;
}

.dot-blog {
  margin: 12px 10px 0 10px;
  width: 5px;
  height: 6px;
}

.mobile-filters {
  display: none;
}

@media only screen and (max-width: 600px) {
  .blog-home h1 {
    font-size: 45px;
    margin: -2rem 0 0 0;
  }

  .blog-home p {
    font-size: 13px;
    padding: 0 2rem 2rem 2rem;
  }

  /* .blog-home h3 {
    font-size: 10px;
    padding: 5px;
    margin: 0 0.2rem;
  } */

  .blog-home-box {
    display: none;
  }

  .article-list {
    margin: 1rem 1rem 2rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .blog1-content {
    height: 115px;
  }

  .blog1-content h1 {
    font-size: 12px;
    height: 45px;
  }

  .blog1-content p {
    font-size: 11px;
  }

  .blog1-content h4 {
    font-size: 12px;
  }

  .blog1-content a {
    font-size: 11px;
    margin: 0;
  }

  .data-lake-blog img {
    height: 250px;
  }

  .dot-blog {
    margin: 6px 5px 0 5px;
    width: 3px;
    height: 4px;
  }

  .mobile-filters {
    display: block;
    padding: 0 5% 0 5%;
  }

  .mobile-filters select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #002c5f;
    border-radius: 4px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .blog-home h1 {
    font-size: 60px;
  }

  .blog-home p {
    font-size: 13px;
  }

  .blog-home h3 {
    font-size: 13px;
  }

  .article-list {
    margin: 0 0.5rem 2rem 0.5rem;
    gap: 30px;
  }

  .data-lake-blog {
    margin: 0;
  }

  .blog1-content {
    height: 120px;
    padding: 20px 10px 0 0;
  }

  .blog1-content h1 {
    font-size: 12px;
    height: 70px;
    padding: 0 0 0 10px;
    margin: 0.5rem 0;
  }

  .blog1-content h4 {
    font-size: 10px;
    width: 70%;
    margin: -2rem 0 0 0;
    padding: 5px 0 5px 10px;
  }

  .blog1-content a {
    font-size: 10px;
  }

  .blog1-content p {
    font-size: 10px;
    padding: 0 0 0 10px;
  }

  .mobile-filters {
    display: none;
  }
}
