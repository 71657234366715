.data-lake-container {
  margin: 5.9rem 0 2rem 0;
  color: #002c5f;
}

.fixed-blog-box {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
  padding: 10px;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.blog-getintouch {
  background: #ee712b;
  color: #ffff;
  padding: 7px 20px;
  border: none;
  border-radius: 192px;
  font-size: 13px;
  font-weight: 300;
  margin: 0 0 0 1rem;
}

.data_lake_image {
  width: 50%;
  display: block;
  margin: 3rem auto;
}

.data_lake_image2 {
  display: block;
  margin: 3rem auto;
  padding: 0rem 2rem;
  width: 50%;
}

.data_lake_image3 {
  display: block;
  margin: 3rem auto;
  padding: 0rem 2rem;
  width: 50%;
}

.data-lake-content h1 {
  font-size: 38px;
  font-weight: 500;
  margin: 0 0 1rem 0;
  padding: 7% 15% 1% 15%;
}

.data-lake-content p {
  font-size: 18px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.22px;
  padding: 1% 15%;
}

.data-lake-content h2 {
  font-size: 28px;
  font-weight: 600;
  padding: 1% 15%;
}

.data-lake-content ol {
  list-style-type: decimal;
  padding: 1% 18%;
}

.data-lake-content ul {
  list-style-type: disc;
  padding: 1% 18%;
}

.data-lake-content li {
  font-size: 18px;
  font-weight: 400;
  line-height: 50px;
  letter-spacing: -0.22px;
}

.data-lake-content h3 {
  font-size: 22px;
  font-weight: 500;
  padding: 1% 15%;
}

.implmentation-text {
  background: #b1ceef;
  display: flex;
  justify-self: center;
  align-items: center;
  display: flex;
  padding: 1% 15%;
  height: 390px;
}

.implmentation-text ol {
  padding: 0 3rem;
}

.implmentation-text h3 {
  padding: 1rem 2rem;
}

.vertical-line-data {
  width: 4px;
  height: 300px;
  background-color: #002c5f;
  margin: 0 auto;
}

.vertical-line-data2 {
  width: 4px;
  height: 300px;
  background-color: #002c5f;
  margin: 0 auto;
}

.blog-content-dot-data {
  display: flex;
  color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  margin: 0 0 1rem 0;
}

.blog-content-dot-data span {
  font-size: 20px;
  font-weight: 400;
}

.image-src {
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px !important;
  margin: -4rem 0 0 0;
}

@media only screen and (max-width: 600px) {
  .fixed-blog-box {
    display: none;
  }

  .data_lake_image {
    width: 80%;
  }

  .data_lake_image2 {
    width: 80%;
    margin: 0rem auto;
    padding: 2rem 2rem;
  }

  .data_lake_image3 {
    width: 80%;
    margin: 1rem auto;
  }

  .data-lake-content h1 {
    font-size: 17px;
    line-height: 25px;
    margin: 0rem 0 1rem 0;
    padding: 1% 15%;
  }

  .blog-content-dot-data span {
    font-size: 11px;
  }

  .data-lake-content p {
    font-size: 14px;
    line-height: 25px;
    padding: 1% 15%;
  }

  .data-lake-content h2 {
    font-size: 16px;
    font-weight: 600;
    padding: 3% 15%;
  }

  .data-lake-content ol {
    list-style-type: decimal;
    padding: 1% 15% 1% 20%;
  }

  .data-lake-content ul {
    list-style-type: decimal;
    padding: 1% 15% 1% 20%;
  }

  .data-lake-content li {
    font-size: 14px;
    line-height: 25px;
  }

  .data-lake-content h3 {
    font-size: 15px;
    font-weight: 600;
    padding: 3% 15%;
  }

  .implmentation-text {
    display: flex;
    padding: 1% 15%;
    height: 485px;
  }

  .implmentation-text ol {
    padding: 0 2rem;
  }

  .implmentation-text h3 {
    padding: 1rem 1rem;
  }

  .vertical-line-data {
    height: 350px;
  }

  .vertical-line-data2 {
    height: 350px;
  }

  .image-src {
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    font-size: 9px !important;
    margin: -1rem 0 0rem 0;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {

  .data-lake-container {
    margin: 5rem 0 2rem 0;
  }

  .data-lake-content h1 {
    font-size: 30px;
    padding: 10% 8% 0% 8%;
  }

  .data_lake_image {
    width: 80%;
    margin: 2rem auto;
  }

  .image-src {
    margin: -2rem 0 0 0;
  }

  .data-lake-content p {
    font-size: 13px;
    line-height: 30px;
    padding: 1% 8%;
  }

  .data-lake-content h2 {
    padding: 1% 8%;
    font-size: 17px;
  }

  .data-lake-content ol {
    padding: 1% 11%;
  }

  .data-lake-content h3 {
    font-size: 15px;
    padding: 1% 8%;
  }

  .data-lake-content li {
    font-size: 13px;
    line-height: 30px;
  }

  .implmentation-text {
    padding: 1% 8%;
  }

  .implmentation-text ol {
    padding: 0 2rem;
  }

  .implmentation-text {
    height: 300px;
  }

  .implmentation-text h3 {
    padding: 0 2rem;
  }

  .vertical-line-data {
    height: 240px;
  }

  .data_lake_image2 {
    width: 70%;
  }

  .vertical-line-data2 {
    height: 240px;
  }

  .data_lake_image3 {
    width: 80%;
    margin: 1rem auto;
  }

  .data-lake-content ul {
    padding: 1% 11%;
  }
}
