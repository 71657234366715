.marketing-automation-consulting {
  font-size: 18px;
  font-weight: 300;
  line-height: 40px;
  letter-spacing: -0.22px;
}

.marketing_blog_container {
  border-radius: 32px;
  background-color: #b1ceef;
  max-width: calc(100% - 30%);
  width: 100%;
  margin: 2rem 15% 3rem 15%;
}

.marketing_blog_text {
  padding: 3.5% 5% 6% 5%;
  display: flex;
  flex-direction: column;
}

.marketing_blog_text span {
  font-size: 20px;
  font-weight: 300;
  line-height: 35px;
  margin: 1rem 0 0 0;
}

.marketing_blog_btn {
    width: 40%;
    text-align: center;
}

@media only screen and (max-width: 600px) {
  .marketing_blog_container {
    display: none;
  }

  .marketing-automation-consulting {
    font-size: 14px;
    line-height: 25px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .marketing-automation-consulting {
    font-size: 13px;
    line-height: 25px;
  }

  .marketing_blog_container {
    max-width: 80%;
    margin: 3% 10%;
  }

  .marketing_blog_text span {
    font-size: 15px;
  }
}