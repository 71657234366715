.tableau_lookingto_section {
  background-color: #b1ceef;
  color: #002c5f;
  padding: 2% 7%;
}

.tableau_lookingto_section h2 {
  font-size: 30px;
}

.tableau_lookingto_section p {
  font-size: 17px;
}

.tableau_consulting_span{
  font-size: 14px;
  font-weight: 400;
  color: #666d76;
}

.Data_Access_and_User_Management {
  width: 70px;
}

.server_implementation {
  width: 80px;
}

.Tableau_Pulse {
  width: 130px;
}

.Tableau_Embedded_Analytics {
  width: 80px;
}

@media screen and (max-width: 600px) {
  .tableau_lookingto_section h2 {
    font-size: 15px;
  }
  
  .tableau_lookingto_section p {
    font-size: 12px;
  }

  .tableau_consulting_span{
    font-size: 12px;
  }
  
  .Data_Access_and_User_Management {
    width: 50px;
  }
  
  .server_implementation {
    width: 60px;
  }
  
  .Tableau_Pulse {
    width: 90px;
  }
  
  .Tableau_Embedded_Analytics {
    width: 55px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .tableau_lookingto_section h2 {
    font-size: 15px;
  }
  
  .tableau_lookingto_section p {
    font-size: 13px;
  }

  .tableau_consulting_span{
    font-size: 12px;
  }
  
  .Data_Access_and_User_Management {
    width: 60px;
  }
  
  .server_implementation {
    width: 70px;
  }
  
  .Tableau_Pulse {
    width: 100px;
  }
  
  .Tableau_Embedded_Analytics {
    width: 65px;
  }
}