@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Poppins", sans-serif;
  color: #002c5f;
  font-display: swap;
}

:root {
  --primary-color: #002c5f;
  overflow-x: hidden;
}

.background-image {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.home-title {
  color: #ffffff;
  position: absolute;
  padding: 0 6%;
  margin: 8% 0 0 0;
}

.home-title h1 {
  font-size: 70px;
  font-weight: 700;
  line-height: 90px;
  margin: 0 0 3rem 0;
}

.visible-mobile {
  display: none;
}

.background {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 3% auto 0 auto;
  animation: none;
}

.know-more-btn {
  background: #0156b8;
  color: #ffffff;
  padding: 8px 25px;
  border: none;
  border-radius: 192px;
  font-size: 16px;
  font-weight: 400;
}

.know-more-btn:hover {
  box-shadow: 5px 5px 10px rgba(43, 69, 78, 0.4);
  font-weight: 500;
}

.talk-to-expert-btn {
  background: #ee712b;
  color: #ffffff;
  padding: 8px 25px;
  border: none;
  border-radius: 192px;
  font-size: 16px;
  font-weight: 400;
  /* width: 100%; */
  display: inline;
}

.talk-to-expert-btn:hover {
  box-shadow: 5px 5px 10px rgba(43, 69, 78, 0.4);
  font-weight: 500;
}

.services-title {
  font-size: 40px;
  font-weight: 600;
  color: #ffffff;
  background-color: #0156b8;
  padding: 1.5rem;
  text-align: center;
  justify-content: center;
  /* margin: -5.6rem 0 0 0; */
}

.services-container {
  margin: 0 0 3rem 0;
  text-align: center;
}

.what-we-do-section {
  display: flex;
  margin: 2rem 0;
  padding: 0 5%;
  text-align: center;
}

.what-we-do-box {
  flex: 1;
  margin: 1.5%;
  padding: 1rem 2rem;
  box-shadow: 0px 4px 10px rgba(100, 98, 98, 0.55);
  border-radius: 20px;
  border: #b3adad;
}

.what-we-do-box img {
  display: block;
  margin: 0 auto;
  height: 65px;
}

.what-we-do-box h3 {
  font-size: 21px;
  margin: 1rem 0;
  font-weight: 400;
}

.what-we-do-box p {
  font-size: 14px;
  font-weight: 400;
}

.what-we-do-para {
  height: 110px;
  color: #666d76;
  margin: 0 0 1rem 0;
}

.what-we-do-keywords {
  height: 80px;
}

.wbdinsights-container {
  margin: -1rem 0 0 0;
}

.insights-section {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1.5rem 0 3rem 0;
}

.insights-quote {
  background: #b1ceef;
  margin: 1% 0 1% 6%;
  padding: 2rem;
  border-radius: 69px;
  box-shadow: 5px 5px 10px rgba(43, 69, 78, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.insights-text {
  color: #002c5f;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  margin: 0 0 1rem 0;
}

.schedule-btn {
  margin: 1rem 0;
}

.insights-video {
  margin: 0 1% 0 5%;
  flex: 1;
}

.insights-video-text {
  font-size: 17px;
  font-weight: 300;
  color: #002c5f;
  line-height: 32px;
  text-align: center;
  margin: 1rem 0rem;
}

.insights-animation-text {
  font-size: 32px;
  font-weight: 300;
  color: #002c5f;
  line-height: 50px;
}

.video-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 6.5%;
}

.animation-video {
  margin: 0.5rem 0 0 0;
  width: 40%;
  height: auto;
}

.connectors-scroll {
  background-color: rgba(177, 206, 239, 0.3);
  border-radius: 40px;
  width: 680px;
  height: 380px;
  overflow: hidden;
  padding: 1rem;
}

.connectors-scroll ul {
  display: flex;
  border-radius: 10px;
  animation: scroll 10s infinite linear;
}

.connectors-scroll li {
  margin: 0px 0px;
  display: flex;
  align-items: center;
}

.connectors-scroll img {
  /* cursor: pointer; */
  transition: 0.2s;
  position: relative;
  border-radius: 10px;
  max-width: fit-content;
}

.connectors-scroll img {
  width: 130px;
  height: auto;
}

.connectors-scroll img:hover {
  transform: scale(1.04);
}

@keyframes scroll {
  from {
    margin-left: 0%;
  }

  to {
    margin-left: -100%;
  }
}

.why-choose-us {
  display: flex;
  flex-direction: column;
  padding: 2% 5%;
  text-align: center;
}

.choose-us-box {
  box-shadow: 0px 4px 10px rgba(100, 98, 98, 0.55);
  flex: 1;
  margin: 1.5%;
  padding: 1rem 2rem;
  border-radius: 20px;
  border: #b3adad;
}

.choose-text-section1 {
  display: flex;
  text-align: center;
}

.choose-text-section1 h3 {
  font-size: 21px;
  font-weight: 400;
  margin: 0.5rem 0 0 0;
  text-align: center;
  border-radius: 20px 20px 0 0;
  height: 70px;
}

.choose-text-section1 p {
  font-size: 14px;
  font-weight: 400;
  /* line-height: 32px; */
  color: #666d76;
}

.home_quote_text {
  place-items: center;
  text-align: center;
}

.home_quote_text h5 {
  font-size: 32px;
  font-weight: 400;
}

.home_quote_text p {
  font-size: 18px;
  margin: 1rem 0 1.5rem 0;
  line-height: 35px;
}

.home_quote-container {
  background: #b1ceef;
  height: 280px;
  margin: 0 0 3rem 0;
  padding: 3% 9% 5% 8%;
}

.monthly_news_container {
  margin: 0 0 2rem 0;
}

.monthly_news_image img {
  width: 380px;
  height: 250px;
}

.big_data_solutions_main {
  background-color: #B1CEEF;
  padding: 3% 6% 3% 6%;
}

.big_data_solutions_content {
  display: flex;
}

.big_data_solutions_content h2 {
  font-size: 50px;
  font-weight: 600;
  margin: -1rem 0 1rem 0;
}

.home_big_data_blog_boxes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.big_data_solutions_content p {
  font-size: 25px;
  font-weight: 400;
}

.big_data_solutions_content ol {
  list-style: disc;
  padding:  0 5%;
}

.big_data_solutions_content li {
  font-size: 21px;
  font-weight: 400;
}

.tailored_expertise_box {
  background-color: #0156B8;
  color: #ffffff;
  text-align: center;
  margin: 2rem 0 0 0;
  padding: 5% 2%;
  border-radius: 26px;
}

.tailored_expertise_box h3 {
  font-size: 32px;
  font-weight: 400;
}

.tailored_expertise_box p {
  font-size: 18px;
  font-weight: 400;
  padding: 0 7%;
  margin: 1rem 0 2rem 0;
}

.big_data_title {
  background-color: #0156B8;
  color: #ffffff;
  padding: 1rem;
  height: 126px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 17px 17px;
}

.big_data_title p{
  font-size: 17px;
  font-weight: 500;
  text-decoration: underline;
}

.big_data_title_span {
  font-size: 18px;
  font-weight: 400;
  margin: 0.8rem 0;
}

.big_data_title_read_more {
  font-size: 15px;
  font-weight: 500;
}

.question-container {
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.faq_question {
  font-size: 30px;
  font-weight: 400;
  margin: 0;
  margin: 15px 0;
}

.faq_answer {
  font-size: 17px;
  text-align: left;
  width: 100%;
  background-color: #B1CEEF;
  font-weight: 400;
}

.faq_list ul{
  list-style: disc;
  padding: 0 5%;
}

.faq_list {
  padding: 1% 1%;
}

.plus_icon {
  width: 22px;
  height: 22px;
  margin: 0.5rem 0 0 0;
  cursor: pointer;
}

.home_faq_section {
  padding: 1.5%;
}

.home_faq_section h2 {
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 1rem 0;
}

.faq-divider {
  border: none;
  border-top: 1px solid #002c5f;
  width: 100%;
}

.home_case_study_box {
  margin: 2.5rem 0;
}

.big_data_image img{
  border-radius: 17px 17px 0 0;
}

.faq_video {
  max-width: 100%;
  margin: 1.5rem 0 0 8%;
}

.insights_pro_section h2 {
  font-size: 40px;
  font-weight: 600;
  margin: 2rem 0 -1rem 0;
  padding: 0 6.5%;
  text-align: center;
}

.insights_pro_para {
  color: #666d76;
}

.insights_pro_section h3 {
  height: 48px;
  line-height: 28px;
  margin: 0.5rem 0;
}

.insights_pro_section h5 {
  font-size: 50px;
  margin: -0.5rem 0;
}

@media only screen and (max-width: 600px) {
  .background {
    margin: 4rem 0 0 0;
    height: auto;
  }

  .home-title h1 {
    font-size: 25px;
    line-height: 30px;
    margin: 3rem 0 1.5rem 0;
    font-weight: 600;
  }

  .visible-mobile {
    display: block;
  }

  .hidden-mobile {
    display: none;
  }

  .services-title {
    font-size: 30px;
    padding: 1rem;
  }

  .insights-section {
    padding: 0rem;
  }

  .insights-quote {
    padding: 1rem;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .insights-text {
    font-size: 12px;
    line-height: 20px;
    margin: 0 0 0.5rem 0;
  }

  .insights-video-section {
    margin: -0.5rem 0 -1.5rem 0;
    text-align: center;
  }

  .insights-video-text {
    font-size: 12px;
    line-height: 18px;
    margin: 2rem 0rem 1rem 0rem;
  }

  .insights-animation-text {
    font-size: 15px;
    line-height: 26px;
  }

  .video-container {
    flex-direction: column-reverse;
    margin: -6rem 0rem 2rem 0;
  }

  .connectors-video-section {
    flex-direction: column;
  }

  .animation-video {
    width: 90%;
    height: 90%;
    margin: 2rem;
  }

  .connectors-scroll {
    border-radius: 20px;
    margin: 6.5rem 0rem 2rem 0rem;
    width: 350px;
    height: 250px;
  }

  .connectors-scroll img {
    width: 80px;
  }

  .what-we-do-section {
    flex-direction: column;
    margin: 1rem 0;
    padding: 0 4%;
  }

  .what-we-do-box h3 {
    font-size: 15px;
  }

  .what-we-do-box p {
    font-size: 12px;
  }

  .what-we-do-keywords {
    height: 60px;
  }

  .what-we-do-box {
    padding: 1rem;
    margin: 3%;
  }

  .what-we-do-para {
    height: 80px;
  }

  .why-choose-us {
    margin: 0.5rem 0 1rem 0;
    padding: 0 6%;
  }

  .choose-us-box {
    box-shadow: none;
    margin: 1rem 0 0.5rem 0;
    padding: 0;
  }

  .choose-text-section1 {
    flex-direction: column;
    text-align: left;
  }

  .choose-text-section1 h3 {
    font-size: 15px;
    height: auto;
    margin: 0 0 0.5rem 0;
    text-align: left;
  }

  .choose-text-section1 p {
    font-size: 12px;
    line-height: 20px;
  }

  .home_quote-container {
    margin: 0 0 2rem 0;
    height: auto;
  }

  .home_quote_text h5 {
    font-size: 20px;
  }

  .home_quote_text p {
    font-size: 12px;
    line-height: 20px;
  }

  .know-more-btn {
    font-size: 12px;
  }

  .talk-to-expert-btn {
    font-size: 12px;
  }

  .monthly_news_image img {
    height: 200px;
  }

  .services-container {
    margin: 0 0 2rem 0;
  }

  .big_data_solutions_content h2 {
    font-size: 22px;
    padding: 4% 6% 0 6%;
  }

  .big_data_solutions_content p {
    font-size: 15px;
    padding: 0 6%;
  }

  .big_data_solutions_content li {
    font-size: 15px;
  }

  .big_data_solutions_content ol {
    padding: 0 12%;
  }

  .tailored_expertise_box h3 {
    font-size: 20px;
  }

  .tailored_expertise_box p {
    font-size: 12px;
  }

  .tailored_expertise_box {
    border-radius: 0;
    margin: 1.5rem 0;
  }

  .big_data_solutions_main {
    padding: 3% 0;
  }

  .big_data_title {
    padding: 0.5rem;
    height: 90px;
  }

  .big_data_title p {
    font-size: 10px;
    padding: 0;
  }

  .big_data_title_span {
    font-size: 10px;
  }

  .big_data_title_read_more {
    font-size: 8px;
  }

  .home_big_data_blog_boxes {
    flex-direction: row;
  }

  .big_data_box {
    margin: 0 0.5rem;
  }

  .home_faq_section {
    padding: 0;
  }

  .home_faq_section h2 {
    font-size: 22px;
    margin: 0.5rem 0 0 0;
  }

  .faq_question {
    font-size: 17px;
  }

  .faq_answer {
    font-size: 12px;
    width: 95%;
  }

  .question-container {
    width: 95%;
  }

  .faq-divider {
    width: 95%;
  }

  .home_case_study_box {
    margin: 6% 5% 8% 5%;
  }

  .faq_video {
    display: none;
  }

  .insights_pro_section h2 {
    font-size: 22px;
    margin: 1rem 0 -1rem 0;
  }

  .insights_pro_section h3 {
    height: auto;
  }

  .insights_pro_section h5 {
    font-size: 20px;
  }

}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .background {
    height: auto;
    margin: 3rem 0 0 0;
  }
  
  .home-title h1 {
    font-size: 60px;
    line-height: 70px;
  }

  .services-title {
    font-size: 28px;
  }

  .what-we-do-section {
    margin: 1.5rem 0 2rem 0;
  }

  .services-container {
    margin: 0 0 2rem 0;
  }

  .what-we-do-box h3 {
    font-size: 14px;
  }

  .what-we-do-box p {
    font-size: 11px;
  }

  .what-we-do-box img {
    height: 48px;
  }

  .what-we-do-box {
    padding: 0.8rem 1rem;
  }

  .choose-text-section1 h3 {
    font-size: 15px;
  }

  .choose-text-section1 p {
    font-size: 11px;
  }

  .choose-us-box {
    padding: 0.5rem 1rem;
  }

  .why-choose-us {
    margin: 1rem 0;
  }

  .know-more-btn {
    font-size: 13px;
  }

  .talk-to-expert-btn {
    font-size: 13px;
  }

  .insights-text {
    font-size: 13px;
  }

  .insights-quote {
    margin: 2% 13%;
  }

  .insights-video-text {
    font-size: 13px;
    line-height: 19.5px;
    margin: 1rem -4rem;
  }

  .insights-animation-text {
    font-size: 20px;
    line-height: 30px;
  }

  .animation-video img {
    max-width: 113%;
    margin-left: -5%;
  }

  .connectors-scroll {
    width: 410px;
    height: 300px;
  }

  .connectors-scroll img {
    width: 100px;
  }

  .home_quote_text h5 {
    font-size: 22px;
  }

  .home_quote_text p {
    font-size: 15px;
  }

  .home_quote-container {
    max-width: 100%;
    margin: 0 0 2rem 0;
    border-radius: 0;
    height: 240px;
  }

  .monthly_news_image img {
    height: 150px;
  }

  .big_data_solutions_content h2 {
    font-size: 30px;
  }

  .big_data_solutions_content p {
    font-size: 13px;
  }

  .big_data_solutions_content li {
    font-size: 13px;
  }

  .big_data_title_span {
    font-size: 13px;
    margin: 0.3rem 0;
  }

  .big_data_title_read_more {
    font-size: 9px;
    text-align: right;
  }

  .tailored_expertise_box h3 {
    font-size: 22px;
  }

  .big_data_title {
    padding: 0.5rem 1rem;
    height: 80px;
  }

  .bi_casestudy_container img {
    height: 100%;
  }

  .tailored_expertise_box p {
    margin: 1rem 0;
  }

  .insights_pro_section h5 {
    font-size: 25px;
  }

  .home_faq_section h2 {
    font-size: 28px;
    margin: 0;
  }

  .faq_question {
    font-size: 19px;
  }

  .faq_video {
    display: none;
  }

  .question-container,.faq-divider {
    width: 80%;
  }

  .faq_answer {
    font-size: 13px;
    width: 80%;
  }

  .home_faq_section {
    margin: 1rem 0 0 0;
  }

  .animation-video {
    display: none;
  }

  
  
}
