.casestudy-home {
  margin: 5rem 0 2rem 0;
  padding: 4rem 0 0 0;
}

.casestudy-home-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.casestudy-home h1 {
  text-align: center;
  font-size: 50px;
  font-weight: 700;
}

.casestudy-home p {
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  margin: 2rem 0;
  padding: 0 12%;
}

.casestudy-container {
  display: flex;
  padding: 0 10%;
}

.casestudy-box {
  flex: 1;
  height: 300px;
  margin: 2% 4%;
}

.casestudy-title {
  background-color: #b1ceef;
  height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}

.casestudy-title-span {
  font-size: 17px;
  font-weight: 400;
  color: #002c5f;
  padding: 0 5%;
}

.read-more {
  text-align: end;
  font-size: 14px;
  padding: 0 5%;
  color: #002c5f;
}

@media only screen and (max-width: 600px) {
  .casestudy-home {
    height: auto;
  }

  .casestudy-home h1 {
    font-size: 24px;
    margin: -2rem 0 0 0;
  }

  .casestudy-home p {
    font-size: 13px;
    padding: 0 2rem;
  }

  .casestudy-container {
    flex-direction: column;
    padding: 0;
  }

  .casestudy-title-span  {
    font-size: 12px;
  }

  .casestudy-title {
    height: 80px;
    padding: 0.5rem;
  }

  .read-more {
    font-size: 8px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .casestudy-home h1 {
    font-size: 30px;
  }

  .casestudy-home p {
    font-size: 13px;
  }

  .casestudy-container {
    padding: 0;
    margin: 0 2rem;
  }

  .casestudy-box {
    margin: 0 2%;
    height: 260px;
  }

  .casestudy-title-span {
    font-size: 13px;
  }

  .casestudy-title {
    height: 100px;
    padding: 0.5rem 0;
  }

  .read-more {
    font-size: 11px;
  }

}