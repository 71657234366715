body {
  overflow-x: hidden !important;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
  background-color: #0156b8;
  padding: 10px 0;
  position: fixed;
  top: 0;
  z-index: 1000;
  height: 95px;
  padding: 0 1.5rem;
}

/* .collapse {
  visibility: visible;
} */

.logo {
  width: 160px;
  height: auto;
  margin-left: 10%;
}

.nav-item a {
  font-size: 16px;
  color: #ffffff;
  /* padding: 7px; */
}

.nav-item a:hover {
  border-bottom: 1px solid #ffffff;
}

.submenu {
  border-radius: 25px;
  background: #0156b8;
  padding: 1rem 1.5rem 1rem 2rem;
  top: 100%;
  margin: 0 0 0 -4.5rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.submenu li {
  padding: 8px 20px;
}

.submenu li a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  /* border-bottom: 0.5px solid #d4caca; */
}

.nav-item .active {
  border-bottom: 1px solid #ffffff;
  /* padding: 8px 25px; */
  /* border-radius: 192px; */
  transition: all 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
  color: #ffffff;
  font-weight: 500;
}

.data-strategy-button .active {
  border-bottom: none;
  color: #ffffff;
  background-color: #ee712b;
  border-radius: 5px;
  padding: 10px;
}

.services-submenu-header {
  text-align: center;
}

.services-submenu-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  padding: 10px;
  border-radius: 25px;
  z-index: 1000;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.8);
  width: 780px;
  background-color: #0156b8;
}

.services-submenu-header a {
  color: #0c7189;
  font-size: 32px;
  font-weight: 700;
}

.services-submenu-section .font-bold-subtitle {
  color: #ffffff;
  font-size: 23px;
  font-weight: 500;
  margin: 0 0 1rem 0;
}

.services-submenu-section .font-bold-subtitle:hover {
  border-bottom: 1px solid #ffffff;
  width: 140px;
}

.services-submenu-section ul li a {
  color: #ffffff;
}

/* .submenu li a::after {
  content: '→'; 
  margin: 20px 10px; 
  font-size: 20px; 
  color: #0c7189;
} */

.services-submenu-header a::after {
  content: "→";
  margin: -10px 10px;
  font-size: 25px;
  color: #ffffff;
}

.p-10 {
  padding: 2rem;
}

.services-submenu-section ul li {
  margin: 1rem 0;
}

.services-submenu-header a.active {
  border-bottom: none;
  color: #0c7189;
}

/* .services-submenu-header a:hover {
  border-bottom: 2px solid #002c5f;
} */

.arrow {
  padding: 0 1rem 0 1rem;
  color: #ffffff;
}

@media only screen and (max-width: 600px) {
  .navbar {
    height: 80px;
  }

  .logo {
    margin-left: 0;
    width: 130px;
  }

  .nav-item {
    padding: 2%;
    margin-left: 10%;
    font-weight: 600;
  }

  .submenu {
    margin: 0 0 0 0.5rem;
  }

  .submenu li a {
    font-size: 12px;
  }

  .arrow {
    display: none;
  }

  .submenu {
    width: 200px;
    padding: 0;
    margin: 0;
    box-shadow: none;
  }

  .p-2 {
    margin: 0.3rem -1.5rem;
  }

  .ml-4 {
    font-size: 14px;
    color: #0156b8;
    font-weight: 400;
  }

  .data-strategy-button {
    background-color: #0c7189;
    color: white;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
    font-size: 18px;
    font-weight: bold;
  }
}


@media only screen and (min-width: 601px) and (max-width: 1026px) {
  .nav-item {
    display: none;
  }

  .hamburger-menu {
    display: block;
  }

  .navbar {
    height: 80px;
  }

  .logo {
    margin-left: 0 0 0 1rem;
    width: 150px;
  }

  .p-2 {
    margin: 0.3rem -1.5rem;
  }

  .ml-4 {
    font-size: 14px;
    color: #002c5f;
    font-weight: 400;
  }

  ul.nav-item.hidden.md\:flex {
    display: none;
  }

  .block.md\:hidden.hamburger-menu {
    display: block;
  }

}
