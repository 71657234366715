.footer-main {
  background-color: #0156B8;
  width: 100%;
  justify-content: space-around;
}

.footer-logo {
  width: 180px;
  height: auto;
  padding: 1rem;
  margin: 0 0 0 1rem;
}

.footer-logo-content {
  color: #ffffff;
  /* font-weight: 300; */
  font-size: 19px;
  margin: -1rem 12rem 0rem 12rem;
  text-align: center;
}

.links-div {
  background-color: rgba(43, 69, 78, 0.25);
  margin-top: 1rem;
  padding: 1rem;
}

.connect-links {
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  margin: 0rem 0rem 1rem 0rem;
}

.contact-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #ffffff;
  transform: translate(2.3%);
  font-size: 19px;
}

.usa-image {
  width: 2.5%;
  height: auto;
  margin-left: -2.4rem;
  margin-top: -0.3rem;
}

.india-image {
  width: 2.5%;
  height: auto;
  margin-left: -2.4rem;
  margin-top: -0.3rem;
}

.gmail-image {
  width: 2.5%;
  height: auto;
  margin-left: -2.4rem;
  margin-top: -0.3rem;
}

.links-container {
  margin-top: 1rem;
}

.social-links {
  display: flex;
  justify-content: center;
  margin: 0.5rem;
}

.linkedin-icon {
  width: 37px;
}

.insta-icon {
  width: 40px;
  margin-left: 1rem;
}

.twitter-icon {
  width: 40px;
  margin-left: 1rem;
}

.quick-links-text {
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  margin: 2rem 0rem 1rem 0rem;
}

.quick-links {
  margin: 0rem 20rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: #ffffff;
  font-size: 18px;
}

.quick-links a {
  margin: 0 -20px;
}

.copyright-text {
  color: #ffffff;
  padding: 1rem;
  font-size: 15px;
}

@media only screen and (max-width: 600px) {
  .footer-logo {
    width: 150px;
  }

  .footer-logo-content {
    font-size: 10px;
    margin: 0rem 2rem;
    text-align: center;
  }

  .connect-links {
    font-size: 12px;
  }

  .contact-info {
    font-size: 10px;
  }

  .usa-image {
    width: 3%;
    margin: -0.7rem;
    margin-top: 0.1rem;
  }

  .india-image {
    width: 3%;
    margin: -0.7rem;
    margin-top: 0.1rem;
  }

  .gmail-image {
    width: 4%;
    margin: 0.09rem 0 0 -1rem;
  }

  .quick-links-text {
    font-size: 12px;
    margin-top: -0.5rem;
  }

  .linkedin-icon {
    width: 30px;
  }

  .insta-icon {
    width: 33px;
  }

  .twitter-icon {
    width: 33px;
  }

  .quick-links {
    font-size: 10px;
    margin: auto;
    margin-top: -0.3rem;
  }

  .copyright-text {
    font-size: 7px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .footer-logo-content {
    font-size: 15px;
    margin: 0rem 2rem;
    text-align: center;
  }

  .connect-links {
    font-size: 15px;
  }

  .contact-info {
    font-size: 15px;
  }

  .usa-image {
    width: 3.5%;
    margin: -1.3rem;
    margin-top: 0rem;
  }

  .india-image {
    width: 3.5%;
    margin: -1.3rem;
    margin-top: 0rem;
  }

  .gmail-image {
    width: 3.5%;
    margin: -0.3rem 0 0 -2.3rem;
  }

  .quick-links-text {
    font-size: 13px;
    margin-top: -0.5rem;
  }

  .linkedin-icon {
    width: 30px;
  }

  .insta-icon {
    width: 33px;
  }

  .twitter-icon {
    width: 33px;
  }

  .quick-links {
    font-size: 10px;
    margin: 0;
    margin-top: -0.3rem;
  }

  .quick-links a {
    margin: 0 -100px;
  }

  .copyright-text {
    font-size: 7px;
  }
}
