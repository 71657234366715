.bi_consulting_landing {
  margin: 5rem 0 1rem 0;
}

.bi_consulting_landing_text {
  padding: 6% 0 0 0;
  text-align: center;
}

.bi_consulting_landing_text h1 {
  font-size: 50px;
  font-weight: 700;
}

.bi_consulting_landing_text p {
  font-size: 20px;
  font-weight: 400;
  padding: 0 7%;
  text-align: left;
  margin: 1rem 0 0 0;
}

.bi_casestudy_container {
  border-radius: 26px;
  box-shadow: 0px 4px 10px rgba(100, 98, 98, 0.55);
}

.bi_finance_case_study_box {
  margin: 4% 7%;
}

.consumer_finance_blog_image {
  flex: 1;
}

.bi_casestudy_container img {
  width: 100%;
}

.bi_casestudy_text_div {
  padding: 0 3%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.bi_casestudy_text_div span {
  font-size: 17px;
  font-weight: 400;
  text-decoration: underline;
  margin: 1rem 0;
}

.bi_casestudy_text_div h3 {
  font-size: 25px;
  font-weight: 500;
  margin: 1rem 0;
}

.bi_casestudy_text_div p {
  font-size: 17px;
  font-weight: 400;
  margin: 1rem 0;
  color: #666d76;
}

.bi_casestudy_text_div a {
  font-size: 17px;
  font-weight: 500;
  margin: 1rem 0;
}

.Ad_Hoc_Analysis_Tools {
  width: 130px;
  padding: 0.3rem 0 0 2rem;
}

.Data_Strategy {
  width: 78px;
}

.Data_Management_Infrastructure {
  width: 135px;
}

.Reporting_and_visualization {
  width: 75px;
}

.Advanced_Analytics {
  width: 62px;
}

.Data_Governance_framework {
  width: 75px;
}

.bi_consulting_title {
  background-color: #b1ceef;
  padding: 0.5rem 1rem;
  color: #002c5f;
  height: 100px;
}

.bi_consulting_title p {
  font-size: 17px;
  font-weight: 500;
  text-decoration: underline;
}

.bi_consulting_title span {
  font-size: 18px;
  font-weight: 400;
}

.consulting_fixed_btn {
  background: #ee712b;
  color: #ffffff;
  padding: 8px 25px;
  border: none;
  border-radius: 192px;
  font-size: 16px;
  font-weight: 400;
  display: block;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 10;
}

.consulting_fixed_btn:hover {
  box-shadow: 5px 5px 10px rgba(43, 69, 78, 0.4);
  font-weight: 500;
}

.bi-infra-img {
  height: 80px;
  margin: 0rem 0 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.data-driven-insights-container ol  {
  list-style-type: decimal;
  padding: 1% 10% 0 10%;
}

.data-driven-insights-container li  {
  font-size: 17px;
}

.benefits-bi-h3 {
  font-size: 25px;
  font-weight: 500;
  padding: 1% 7% 0 7%;
}

.read-blog-bi {
  font-size: 17px;
  text-decoration: underline;
}

.rounded-brackets {
  font-size: 17px;

}

@media only screen and (max-width: 600px) {
  .bi_consulting_landing_text h1 {
    font-size: 24px;
    padding: 1% 5%;
  }

  .bi_consulting_landing_text p {
    font-size: 13px;
    margin: 1rem 0 0rem 0;
  }

  .bi-infra-img {
    height: 50px;
    margin: 0;
  }

  .data-driven-insights-container li {
    font-size: 12px;
  }

  .Ad_Hoc_Analysis_Tools {
    width: 103px;
  }

  .Data_Strategy {
    width: 53px;
  }

  .Data_Management_Infrastructure {
    width: 100px;
  }

  .Reporting_and_visualization {
    width: 55px;
  }

  .Advanced_Analytics {
    width: 43px;
  }

  .Data_Governance_framework {
    width: 50px;
  }

  .bi_casestudy_text_div {
    padding: 5% 5%;
  }

  .bi_casestudy_text_div span {
    font-size: 12px;
  }

  .bi_casestudy_text_div h3 {
    font-size: 16px;
  }

  .bi_casestudy_text_div p {
    font-size: 12px;
  }

  .bi_casestudy_text_div a {
    font-size: 12px;
  }

  .data-strategy-expert {
    display: none;
  }

  .bi_consulting_title {
    height: 75px;
  }

  .bi_consulting_title span {
    font-size: 13px;
  }

  .bi_consulting_title p {
    font-size: 8px;
  }

  .consulting_fixed_btn {
    font-size: 13px;
  }

  .benefits-bi-h3 {
    font-size: 15px;
  }

  .read-blog-bi {
    font-size: 12px;
  }

  .rounded-brackets {
    font-size: 12px;
  } 

}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .bi_consulting_landing_text h1 {
    font-size: 30px;
  }

  .bi_consulting_landing_text p {
    font-size: 13px;
  }

  .data-driven-insights-container li {
    font-size: 13px;
  }

  .bi_consulting_title p {
    font-size: 11px;
  }

  .bi_consulting_title span {
    font-size: 13px;
  }

  .bi_consulting_title {
    height: 90px;
  }

  .benefits-bi-h3 {
    font-size: 23px;
  }

  .read-blog-bi {
    font-size: 13px;
  }

  .rounded-brackets {
    font-size: 13px;
  }

  .bi-infra-img {
    height: 60px;
  }

  .Ad_Hoc_Analysis_Tools {
    width: 110px;
  }

  .Data_Strategy {
    width: 60px;
  }

  .Data_Management_Infrastructure {
    width: 115px;
  }

  .Reporting_and_visualization {
    width: 60px;
  }

  .Advanced_Analytics {
    width: 50px;
  }

  .Data_Governance_framework {
    width: 60px;
  }

  .data-driven-insights-box {
    padding: 1rem;
  }

  .bi_casestudy_text_div p {
    font-size: 13px;
  }

  .bi_casestudy_text_div span {
    font-size: 11px;
  }

  .bi_casestudy_text_div h3 {
    font-size: 15px;
  }

  .bi_casestudy_text_div a {
    font-size: 11px;
  }

}
