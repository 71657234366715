.insights-main {
  color: #002c5f;
  width: 100%;
  margin: 9rem 0 0 0;
}

.landing-section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 1% 4%;
}

.wbd-title {
  font-size: 50px;
  font-weight: 700;
}

.landing-section p {
  font-size: 20px;
}

.landing-section ul {
  list-style-type: disc;
  line-height: 38px;
  padding: 0 3% 0 3%;
}

.landing-section li {
  font-size: 17px;
}

.pricing_insights_btn {
  margin: 3rem 0 0 0;
}

.benefits_insights_pro h2 {
  font-size: 40px;
  font-weight: 600;
  padding: 1.5% 4%;
  margin: 2rem 0 0 0;
  background-color: #0156b8;
  color: #ffffff;
}

.benefits_insights_pro h3 {
  height: 48px;
}

.hiring_insights {
  width: 100px;
}

.we_help_insights h2 {
  font-size: 40px;
  font-weight: 600;
  margin: 2rem 0 0 0;
  background-color: #0156b8;
  color: #ffffff;
  padding: 1.5% 4%;
}

.we_help_insights p {
  font-size: 17px;
  padding: 2% 4% 0 4%;
}

.we_help_insights ul {
  list-style: disc;
  text-align: left;
  padding: 0 2%;
}

.we_help_insights li {
  font-size: 14px;
  color: #666d76;
}

.scroll_insights {
  padding: 1% 0;
  background-color: #b1ceef;
  margin: 3rem 0 2rem 0;
}

.scroll_insights p {
  font-size: 22px;
  margin: 0 6%;
  text-align: center;
}

.insights_pro_btn {
  display: flex;
  justify-content: center;
  margin: -1rem 0;
}

.container-pricing {
  margin: 0 0 3rem 0;
}

/* .bottom-text {
  font-size: 23px;
  font-weight: 400;
  text-align: center;
  margin: 0rem 10rem 2rem 10rem;
} */

.box-70 {
  flex: 0 0 60%;
}

.insights-img {
  align-self: center;
  justify-content: center;
  flex: 0 0 40%;
}

.pricing-title {
  font-size: 40px;
  font-weight: 600;
  margin: 2rem 0;
  background-color: #0156b8;
  color: #ffffff;
  padding: 1.5% 4%;
}

/* .timelinebox {
  font-size: 30px;
  font-weight: 600;
}

.laptop-img {
  width: 350px;
  margin: 1rem 0rem 5rem 4rem;
} */

.scroll {
  background-color: #b1ceef;
  margin: auto;
  width: 100%;
  overflow: hidden;
  opacity: 0.7;
  padding: 1rem;
}

.scroll ul {
  padding: 0;
  display: flex;
  border-radius: 10px;
  animation: scroll 20s infinite linear;
}

.scroll li {
  margin: 0 70px;
  display: flex;
  align-items: center;
}

.scroll img {
  transition: 0.2s;
  position: relative;
  border-radius: 10px;
  max-width: fit-content;
}

.scroll-img1 {
  width: 185px;
}

.scroll-img2 {
  width: 190px;
  margin-top: 0.5rem;
}

.scroll-img3 {
  width: 165px;
  margin-top: 2rem;
}

.scroll-img4 {
  width: 160px;
  margin-top: 1rem;
}

.scroll-img5 {
  width: 230px;
}

.scroll-img6 {
  width: 180px;
}

.scroll-img7 {
  width: 250px;
  margin-top: 1rem;
}

.scroll img:hover {
  transform: scale(1.04);
}

@keyframes scroll {
  from {
    margin-left: 0%;
  }

  to {
    margin-left: -100%;
  }
}

.pricing_list li {
  padding: 10px;
}

.pricing_list li {
  font-size: 17px;
  font-weight: 400;
}

ol,
ul {
  padding: 0;
}

.package-container {
  display: flex;
  justify-content: center;
  color: #002c5f;
  padding: 0 4%;
}

.package-container h3 {
  font-size: 23px;
  font-weight: 500;
  color: #f5f5f5;
  background-color: #0156b8;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.packages_pricing1 {
  flex: 1;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 0px 0px; */
  border-right: 0.5px solid #575d65;
}

.packages_pricing2 {
  flex: 1;
  text-align: center;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  border-right: 0.5px solid #575d65;
}

.packages_pricing3 {
  flex: 1;
  text-align: center;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

.bg-white {
  background-color: #ffffff !important;
}

.monthly_pricing {
  height: 75px;
  font-size: 17px;
  background-color: #E7F5F5;
}

.pricing_list li {
  height: 50px;
}

.tick-img {
  width: 25.5px;
  margin-left: 45%;
}

/* .container-timeline {
  margin: 2rem;
}

.timeline h1 {
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  margin: 5rem;
}

.timeline {
  position: relative;

  &::before {
    content: "";
    background: #000000;
    width: 5px;
    height: 69.7%;
    position: absolute;
    left: 50%;
    top: 12%;
    transform: translateX(-50%);
  }
}

.timeline-item {
  width: 100%;
  margin-bottom: 70px;

  &:nth-child(even) {
    .timeline-content {
      float: right;
      .date {
        right: auto;
        left: 0;
      }

      &::after {
        content: "";
        position: absolute;
        border-style: solid;
        width: 0;
        height: 0;
        top: 30px;
        left: -15px;
        border-color: transparent #f5f5f5 transparent transparent;
      }
    }
  }

  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

.timeline-content {
  position: relative;
  width: 42%;
  padding: 30px 58px;
  border-radius: 4px;
  background: #b1ceef;
  box-shadow: 0 20px 25px -15px rgba(0, 0, 0, 0.3);
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    border-style: solid;
    width: 0;
    height: 0;
    top: 30px;
    right: -15px;
    border-color: transparent transparent transparent #f5f5f5;
  }
} */

/* .timeline-content p {
  font-size: 21px;
  font-weight: 300;
  margin-top: 1rem;
}

.timeline-img {
  width: 70px;
  height: 70px;
  background: #0156b8;
  position: absolute;
  left: 50%;
  margin-top: 25px;
  margin-left: -33px;
  text-align: center;
  justify-content: center;
  font-size: 43px;
  color: #ffffff;
} */

.calendly-badge-widget .calendly-badge-content {
  background-color: #ee712b !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  font-family: "Poppins", sans-serif !important;
}

.calendly-badge-widget .calendly-badge-content:hover {
  box-shadow: rgba(0, 0, 0, 0.5) 5px 5px 5px;
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  .landing-section {
    margin: -3rem 0 0 0;
  }

  .wbd-title {
    font-size: 24px;
    text-align: center;
  }

  .landing-section p {
    font-size: 13px;
  }

  .landing-section li {
    font-size: 12px;
    line-height: 25px;
  }

  .landing-section ul {
    padding: 0 6%;
  }

  .pricing_insights_btn {
    margin: 1rem 0 0 0;
  }

  .benefits_insights_pro h2 {
    font-size: 20px;
    margin: 1rem 0 -1rem 0;
    padding: 3% 0;
    text-align: center;
  }

  .hiring_insights {
    width: 70px;
  }

  /* .bottom-text {
    font-size: 13px;
    margin: 1rem;
  } */

  .insights-img {
    display: none;
  }

  .scroll li {
    margin: 0 40px;
  }

  .scroll-img1 {
    width: 100px;
  }

  .scroll-img2 {
    width: 120px;
    margin-top: 0.5rem;
  }

  .scroll-img3 {
    width: 100px;
    margin-top: 1.3rem;
  }

  .scroll-img4 {
    width: 110px;
    margin-top: 1rem;
  }

  .scroll-img5 {
    width: 140px;
    margin-top: 0.3rem;
  }

  .scroll-img6 {
    width: 100px;
  }

  .scroll-img7 {
    width: 170px;
    margin-top: 0.5rem;
  }

  /* .timeline-img {
    width: 50px;
    height: 50px;
    font-size: 30px;
  }

  .timeline-content {
    padding: 30px 10px;
  }

  .timeline h1 {
    font-size: 15px;
    margin: 3rem 1rem 4rem 1rem;
    font-weight: 600;
  }

  .timelinebox {
    font-size: 15px;
  } */

  /* .laptop-img {
    width: 250px;
    margin: 1rem 0rem 3rem 0.5rem;
  } */

  .package-container {
    flex-wrap: nowrap;
  }
  
  .tick-img {
    width: 9.9px;
  }

  .pricing-title {
    font-size: 20px;
    margin: 0 0 1rem 0;
    padding: 3% 0;
    text-align: center;
  }

  .we_help_insights h2 {
    font-size: 20px;
    padding: 3% 0;
    text-align: center;
  }

  .we_help_insights p {
    font-size: 12px;
    margin: 0.5rem 0 -1rem 0;
  }

  .we_help_insights ul {
    padding: 0 8%;
  }

  .scroll_insights p {
    font-size: 14px;
  }

  .we_help_insights li {
    font-size: 12px;
  }

  .calendly-badge-widget .calendly-badge-content {
    font-size: 12px;
    height: 35px;
  }

  .package-container h3 {
    font-size: 8px;
    height: 50px;
  }

  .pricing_list li {
    font-size: 7px;
    height: 30px;
  }

  .monthly_pricing {
    font-size: 7px;
    height: 50px;
  }

  .insights_pro_btn {
    display: none;
  }

  .scroll_insights {
    margin: 1.5rem 0 2rem 0;
  }

  .container-pricing {
    margin: 0 0 2rem 0;
  }

  /* .timeline {
    &::before {
      left: 50px;
      height: 680px;
    }

    .timeline-img {
      left: 57px;
    }

    .timeline-content {
      max-width: 100%;
      width: auto;
      margin-left: 70px;
    }

    .timeline-item {
      &:nth-child(even) {
        .timeline-content {
          float: none;
        }
      }

      &:nth-child(odd) {
        .timeline-content {
          &::after {
            content: "";
            position: absolute;
            border-style: solid;
            width: 0;
            height: 0;
            top: 30px;
            left: -15px;
            border-color: transparent #f5f5f5 transparent transparent;
          }
        }
      }
    }
  } */
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
  .insights-main {
    margin: 6rem 0 0 0;
  }

  .wbd-title {
    font-size: 30px;
  }

  .landing-section p {
    font-size: 13px;
  }

  .landing-section li {
    font-size: 13px;
    line-height: 25px;
  }

  .scroll li {
    margin: 0 40px;
  }

  .scroll-img1 {
    width: 170px;
  }

  .scroll-img2 {
    width: 190px;
  }

  .scroll-img3 {
    width: 160px;
  }

  .scroll-img4 {
    width: 170px;
  }

  .scroll-img5 {
    width: 200px;
  }

  .scroll-img6 {
    width: 170px;
  }

  .scroll-img7 {
    width: 220px;
  }

  /* .bottom-text {
    margin: -2rem 0 0 0;
    font-size: 15px;
  } */

  .insights-img {
    margin: 2rem 0rem 0rem 0rem;
    width: 500px;
    height: 250px;
  }

  .tick-img {
    width: 17px;
  }

  .scroll_insights p {
    font-size: 15px;
  }

  .container-pricing {
    margin: 0 0 2rem 0;
  }

  .we_help_insights p {
    font-size: 13px;
  }

  .we_help_insights h2, .benefits_insights_pro h2, .pricing-title, .insights_pro_section h2 {
    font-size: 28px;
  }

  .monthly_pricing {
    font-size: 13px;
  }

  .pricing_list li {
    font-size: 13px;
    height: 40px;
  }

  .package-container h3 {
    font-size: 15px;
    height: 80px;
  }

  .hiring_insights {
    width: 80px;
  }
}
