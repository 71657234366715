.policy-main {
    line-height: 28px;
    margin: 2rem 10rem;
    color: #002c5f;
}

.policy-main h1 {
    font-size: 4rem;
    margin-bottom: 2rem;
    font-weight: 600;
    margin-top: 2rem;

}

.policy-main h2 {
    font-size: 2rem;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.policy-main p {
    margin-top: 1rem;
}

.policy-main h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.policy-logo {
    width: 190px;
    margin-left: -0.7rem;
}

.policy-main ul {
    list-style-position: inside;
    list-style-type: disc;
    margin-left: 2rem;
    line-height: 40px;
}

.policy-main h4 {
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.points {
    margin-left: 1.4rem;
}

@media only screen and (max-width: 600px) {
    .policy-main {
        margin-left: 1rem;
        margin-right: 2rem;
        line-height: 24px;

    }

    .policy-main h1 {
        font-size: 3rem;
    }

    .policy-main h2 {
        font-size: 1.5rem;
    }

    .policy-main h3{
        font-size: 1.4rem;
    }
}