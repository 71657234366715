.about-main {
  margin: 5rem 0rem;
  color: #002c5f;
}

.story-section {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0rem 5rem;
}

.about-main h1 {
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}

.story-text {
  font-size: 23px;
  font-weight: 400;
  margin: 3rem 0rem 0rem 0rem;
  text-align: center;
}

.story-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-mobile {
  display: none;
}

.philosophy-section {
  margin: 0rem 2.5rem;
  display: flex;
  justify-content: space-around;
}

.philosophy {
  display: flex;
  flex-direction: column;
}

.philosophy-box {
  background: #b1ceef;
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  padding: 3rem 5rem;
  border: none;
  border-radius: 69px;
  margin: 2rem;
  flex: 1;
}

.boxes-section {
  background: #b1ceef;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 2rem 8rem;
}

.guide-box {
  background-color: rgba(255, 255, 255, 0.75);
  padding: 2rem;
  border: none;
  border-radius: 27px;
  margin: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.guide-box p {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.wall-section {
  display: flex;
  justify-content: center;
  margin: 4rem;
}

.about_wall {
  border-radius: 15px;
}

.values-container {
  display: flex;
  flex-direction: row;
  margin: 4rem;
  text-align: center;
}

.values-container h2 {
  font-size: 30px;
  font-weight: 600;
  margin: 1.5rem -1.5rem;
}

.values-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
}

.value-text {
  background-color: rgba(177, 206, 239, 0.45);
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding: 2rem;
  border: none;
  border-radius: 27px;
  margin: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  margin-top: -2rem;
}

.values-box:hover .value-text {
  opacity: 1;
}

.values-box img {
  transition: opacity 0.3s;
}

.values-box:hover img {
  opacity: 0.2;
}

.values-box:hover h2 {
  opacity: 0;
}

.joinus-section {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin: 2rem 19rem;
}

@media only screen and (max-width: 600px) {
  .story-section {
    height: auto;
    margin: 0rem 0rem;
  }

  .about-main h1 {
    font-size: 25px;
    margin: 2rem 0rem 0rem 0rem;
  }

  .story-text {
    font-size: 13px;
    margin: 2rem 2rem;
  }

  .story-image {
    margin: 2rem 0rem;
  }

  .about-mobile {
    display: inline;
    width: auto;
  }

  .about-desktop {
    display: none;
  }

  .philosophy h1 {
    font-size: 25px;
    margin: 1.5rem 0 0 0;
  }

  .philosophy-section {
    display: flex;
    flex-direction: column;
  }

  .philosophy-box {
    font-size: 13px;
    padding: 2rem;
    margin: 1rem 0rem 0 0rem;
  }

  .boxes-section {
    padding: 0rem 0rem;
    margin: 1.5rem 0;
  }

  .guide-box {
    padding: 0.5rem;
    margin: 1rem 0.5rem;
  }

  .guide-box p {
    font-size: 10px;
  }

  .guide-box img {
    width: 50%;
  }

  .wall-section {
    margin: 1rem;
    width: auto;
  }

  .values-container {
    margin: 0.5rem;
    flex-direction: column;
  }

  .values-container h2 {
    font-size: 12px;
    margin: 1rem 0.5rem;
  }

  .values-box {
    display: flex;
    align-items: center;
  }

  .values-box img {
    width: 50%;
  }

  .value-text {
    font-size: 10px;
    opacity: 1;
    width: 65%;
    height: 80%;
    padding: 1rem;
    margin: 0rem;
    border-radius: 16px;
  }

  .values-box.value-text {
    display: block;
  }

  .customer-box {
    margin: 0 0 0 -40%;
    text-align: left;
  }

  .textbox1 {
    margin-left: 35%;
  }

  .ownership-box {
    margin: 0 0 0 -45%;
    text-align: left;
  }

  .textbox3 {
    margin-left: 35%;
  }

  .progress-box {
    margin: 0 -85% 0 0;
    text-align: left;
  }

  .textbox2 {
    margin-left: -35%;
  }

  .empathy-box {
    margin: 3% -93% 0 0;
    text-align: left;
  }

  .textbox4 {
    margin-left: -35%;
  }

  .values-box:hover img {
    opacity: 1;
  }

  .values-box:hover h2 {
    opacity: 1;
  }

  .joinus-section {
    font-size: 10px;
    margin: 2rem 3rem -2rem 3rem;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .story-section {
    height: auto;
    margin: 9rem 3rem 0 3rem;
  }

  .story-image {
    margin: 3rem 0;
  }

  .story-text {
    font-size: 13px;
    margin: 1.5rem 0 0 0;
  }

  .about-main {
    margin: 0;
  }

  .about-main h1 {
    font-size: 28px;
  }

  .philosophy-section {
    margin: 0;
  }

  .philosophy-box {
    font-size: 13px;
    padding: 2rem;
    margin: 1.5rem;
  }

  .boxes-section {
    padding: 1.5rem 2rem;
  }

  .guide-box {
    padding: 1rem;
    margin: 1rem;
  }

  .guide-box p {
    font-size: 14px;
  }

  .guide-box img {
    width: 42px;
  }

  .wall-section {
    margin: 2rem 3rem;
  }

  .value-text {
    font-size: 13px;
    position: relative;
    opacity: 1;
    margin: 0.5rem;
    padding: 1rem;
    width: 95%;
    height: 95%;
  }

  .values-box:hover img {
    opacity: 1;
  }

  .values-box:hover h2 {
    opacity: 1;
  }

  .values-box img {
    width: 60%;
    margin-left: 2rem;
  }

  .values-container {
    margin: 2rem 1rem;
  }

  .customer-box img {
    width: 70%;
  }

  .values-box h2 {
    font-size: 17px;
  }

  .customer-box h2 {
    margin-top: 2.4rem;
  }

  .ownership-box h2 {
    margin-top: 1.7rem;
  }

  .empathy-box h2 {
    margin-top: 2.4rem;
  }

  .joinus-section {
    margin: 3rem 2rem;
    font-size: 13px;
  }
}
