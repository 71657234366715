.data_visualization_image {
  display: block;
  margin: 3rem auto;
  width: 50%;
}

.implmentation-visualization-text {
  background: #b1ceef;
  height: 240px;
  display: flex;
  justify-self: center;
  align-items: center;
  display: flex;
  padding: 1% 15%;
}

.implmentation-visualization-text p {
  padding: 0 2rem;
}

.vertical-line {
  width: 5px;
  height: 200px;
  background-color: #002c5f;
  margin: 0 auto;
}

.vertical-line2 {
  width: 3px;
  height: 200px;
  background-color: #002c5f;
}

@media only screen and (max-width: 600px) {
  .data_visualization_image {
    padding: 0rem 2rem 0 2rem;
    width: 95%;
    margin: 1rem auto;
  }

  .implmentation-visualization-text p {
    padding: 0 1rem;
  }

  .implmentation-visualization-text {
    padding: 1% 15%;
    height: 300px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .data_visualization_image {
    width: 75%;
  margin: 2rem auto;
  }

  .implmentation-visualization-text {
    padding: 1% 8%;
    height: 200px;
  }

  .vertical-line {
    height: 160px;
  }

  .vertical-line2 {
    height: 160px;

  }
}

 